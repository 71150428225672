<template>
  <div id="app" class="font-exo h-screen">
    <DynamicModal v-if="displayModal" :title="modalTitle" :message="modalMessage" />
    <UnsupportedBrowser v-if="!isBrowserSupported" />
    <template v-else>
      <loading-ui v-if="displaySpinner" modal :message="spinnerMessage" />
      <router-view :key="route.path" />
      <app-info-modal />
      <TermsAndConditionsModal />
      <PrivacyPolicyModal />
      <ModalIdle :status="isIdleModalOpen" v-if="isIdleModalOpen" @closeModal="closeModal" />
      <ModalGeneric
        v-if="globalStore.appReload"
        title="Warning"
        message="Are you sure that you want to reload the system?"
        confirm-label="Yes, reload"
        @confirm="handleReload"
        @cancel="handleReloadCancel"
        data-test="app-modal-reload"
      />
      <notifications group="error" position="bottom left" classes="tmrw-notification">
        <template #body="props">
          <div group class="tmrw-error-notification">
            <div class="content">
              <div class="title"><div v-html="props.item.title"></div></div>
              <div v-html="props.item.text"></div>
            </div>
          </div>
        </template>
      </notifications>
      <notifications group="warn" position="bottom left" classes="tmrw-notification">
        <template #body="props">
          <div group class="tmrw-warn-notification">
            <div class="content">
              <div class="title"><div v-html="props.item.title"></div></div>
              <div v-html="props.item.text"></div>
            </div>
          </div>
        </template>
      </notifications>
      <notifications group="customError" position="bottom left" classes="tmrw-notification">
        <template #body="props">
          <div class="tmrw-mass-create-notification" :class="[props.item.data.customClasses]">
            <button class="close" @click="props.close">
              <i class="is-icon-left mr-3 far fa-times-circle" aria-hidden="true"></i>
            </button>

            <img
              :src="props.item.data.icon"
              width="31.5px"
              style="height: 28px !important; display: block; margin: auto"
            />

            <div class="content">
              <p class="title">{{ props.item.title }}</p>
              <div v-html="props.item.text"></div>
            </div>
          </div>
        </template>
      </notifications>
      <notifications group="success" position="bottom left" classes="tmrw-notification">
        <template #body="props">
          <div group class="tmrw-success-notification">
            <div class="content">
              <div class="title"><div v-html="props.item.title"></div></div>
              <div v-html="props.item.text"></div>
            </div>
          </div>
        </template>
      </notifications>
      <notifications group="info" position="bottom center" classes="tmrw-notification" />
    </template>
  </div>
</template>

<script setup lang="ts">
import { useIdle } from '@vueuse/core'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import AppInfoModal from '@/components/AppInfoModal/AppInfoModal.vue'
import LoadingUi from '@/components/LoadingUi/LoadingUi.vue'
import ModalIdle from '@/components/ModalIdle/ModalIdle.vue'
import ModalGeneric from '@/components/ModalGeneric/ModalGeneric.vue'
import UnsupportedBrowser from '@/components/UnsupportedBrowser/UnsupportedBrowser.vue'
import DynamicModal from '@/components/DynamicModal/DynamicModal.vue'
import TermsAndConditionsModal from '@/components/TermsAndConditionsModal/TermsAndConditionsModal.vue'
import PrivacyPolicyModal from '@/components/PrivacyPolicyModal/PrivacyPolicyModal.vue'
import { IDLE_TIMEOUT } from './constants'
import { onBeforeUnmount, onMounted, onUpdated, ref } from 'vue'
import useUnsupportedBrowser from './composables/useUnsupportedBrowser'
import useGetters from './composables/useGetters'
import useActions from './composables/useActions'
import { useRoute, useRouter } from 'vue-router'
import { useGlobalStore } from '@/store/global'
import * as ss from '@/config/session-storage-help'
import toast from '@/config/toast'

dayjs.extend(relativeTime)

const { idle } = useIdle(IDLE_TIMEOUT * 60 * 1000)

const router = useRouter()
const route = useRoute()

const { isBrowserSupported } = useUnsupportedBrowser()

const interval = ref<null | NodeJS.Timeout>(null)
const isIdleModalOpen = ref(false)
const excludedIdleRoutes = ref<string[]>([])

const { displaySpinner, spinnerMessage } = useGetters('spinnerModule')
const { displayModal, modalTitle, modalMessage } = useGetters('modalModule')
const { tokenExpiresAt } = useGetters('authModule')
const { appVersion } = useGetters('appInfoModule')

const { saveState, resetState } = useActions('dataModule')
const { fetchApiVersion, fetchAppVersion, fetchBulkImportApiVersion } = useActions('appInfoModule')
const { logout, renewToken } = useActions('authModule')
const { resetTicketProcess } = useActions('newTicketModule')
const { fetchScreeningStatus } = useActions('screeningStatusModule')

const globalStore = useGlobalStore()

const closeModal = (keepSignedIn: boolean) => {
  if (keepSignedIn) {
    resetAll()
  } else {
    handleSignOut()
  }
  isIdleModalOpen.value = false
}

const handleSignOut = async () => {
  await logout({ resetTicketProcess, router })
}
const handleRenewToken = async () => {
  try {
    await renewToken()
  } catch (error) {
    handleSignOut()
  }
}

const startAutoLogoutCounter = () => {
  interval.value = setInterval(idleInterval, 1000)
}
const getReportRedirect = () => {
  const urlSplit = route.fullPath.split('filename')
  if (urlSplit.length > 1) {
    const filename = urlSplit[1].replace('%3D', '')
    if (filename) {
      const redirectUrl = `https://os-staging.cryogenics.life/openspecimen/rest/ng/login-audit-logs/report-file?filename=${filename}`
      window.location.href = redirectUrl
    }
  }
}

const idleInterval = () => {
  const currentRoute = router.currentRoute.value.path
  if (tokenExpiresAt.value && currentRoute !== '/' && !isIdleModalOpen.value) {
    const currentTime = dayjs()
    const tokenExpiration = dayjs(tokenExpiresAt.value)
    const tokenExpirationDiff = currentTime.diff(tokenExpiration, 'seconds')

    // when we hit the 7 minute mark from first aquiring a token, we
    // have 2 choices:
    // 1. if the user has been using the app, then we automatically renew
    // the token without showing the logout modal. No matter what screen.
    // 2. if the user has not being actively using the app for the past 3
    // minutes (e.g. user is on another tab), then we check if we CAN show
    // the logout modal. If the user is in an excluded route (e.g. /reformatting),
    // then we silently renew the token. Otherwise, we finally show the modal
    if (tokenExpirationDiff >= 0) {
      if (idle.value) {
        if (excludedIdleRoutes.value.includes(currentRoute)) {
          resetAll()
        } else {
          clearAutoLogout()
          isIdleModalOpen.value = true
        }
      } else {
        resetAll()
      }
    }
  } else {
    clearAutoLogout()
  }
}

// Clear the interval and reset the counter for AutoLogout.
const clearAutoLogout = () => {
  if (interval.value) {
    clearInterval(interval.value)
  }
  interval.value = null
}

const resetAll = async () => {
  clearAutoLogout()
  startAutoLogoutCounter()
  await handleRenewToken()
}

const handleReloadCancel = async () => {
  globalStore.showAppReload(false)
}

const handleReload = async () => {
  try {
    // Calls an api endpoint to clear session
    await handleSignOut()
    // Resets 'louis' session storage
    ss.setDefaultSessionStorage()
    // Pinia store is cleared when reloading app
    globalStore.showAppReload(false)
    window.location.reload()
  } catch (error) {
    toast.error(error)
  }
}

const getAppVersion = async () => {
  await fetchAppVersion()
  if (globalStore.appUpdated) {
    toast.success(`App version updated to ${appVersion.value}`)
    globalStore.showAppUpdated(false)
  }
}

onUpdated(() => {
  clearAutoLogout()
  startAutoLogoutCounter()
})

onMounted(async () => {
  resetState()
  window.addEventListener('beforeunload', () => {
    saveState()
  })
  fetchBulkImportApiVersion()
  fetchApiVersion()
  getAppVersion()
  fetchScreeningStatus()
  startAutoLogoutCounter()
  getReportRedirect()
})

onBeforeUnmount(() => {
  clearAutoLogout()
})
</script>

<style lang="scss">
html {
  @apply text-base;
}

.notifications {
  @apply p-3;

  .notification-title {
    @apply text-base break-words;
  }
}

.vue-notification-group {
  width: 500px !important;
}
.custom-height {
  height: 28px !important;
}
.container {
  display: flex !important;
  align-items: center !important;
}

.tmrw-notification {
  @apply text-tmrw-blue-dark bg-white p-4 text-2xl rounded-lg font-bold border-l-0 shadow-md m-8 mt-0;

  &.warn {
    @apply text-tmrw-warning bg-white;
    @apply border-solid border-1 border-tmrw-blue;
  }

  &.custom-warn {
    @apply text-tmrw-blue bg-white;
    @apply border-solid border-1 border-tmrw-blue;

    .notification-content {
      @apply text-tmrw-blue text-base font-medium;
    }
  }

  &.error {
    @apply text-tmrw-error bg-white;
    @apply border-solid border-1 border-tmrw-blue;

    .notification-content {
      @apply text-black text-base font-medium;
    }
  }

  &.success {
    @apply text-tmrw-success bg-white;
    @apply border-solid border-1 border-tmrw-blue;
  }
}

.spinner {
  @apply w-8 h-8 bg-cover animate-spin;
  background-image: url('./assets/spinner.svg');
}

.tmrw-mass-create-notification {
  @apply bg-white p-5 relative text-left text-2xl rounded-lg font-bold shadow-md m-8 flex mt-0 border-solid border-1 text-tmrw-blue;
}

.tmrw-success-notification {
  @apply text-tmrw-blue-dark bg-white p-4 text-2xl rounded-lg font-bold border-l-0 shadow-md m-8 mt-0;
  @apply text-tmrw-success bg-white;
  @apply border-solid border-1 border-tmrw-blue;
}

.tmrw-error-notification {
  @apply text-tmrw-blue-dark bg-white p-4 text-2xl rounded-lg font-bold border-l-0 shadow-md m-8 mt-0;
  @apply text-tmrw-error bg-white;
  @apply border-solid border-1 border-tmrw-blue;
}

.tmrw-warn-notification {
  @apply text-tmrw-blue-dark bg-white p-4 text-2xl rounded-lg font-bold border-l-0 shadow-md m-8 mt-0;
  @apply text-tmrw-warning bg-white;
  @apply border-solid border-1 border-tmrw-blue;
}

.icon-wrapper {
  @apply flex items-center mr-2.5;
}

.content {
  @apply py-0 px-5 grow;
}

.close {
  @apply absolute top-1.5 right-1.5 ml-2 p-0 outline-none;
}

.close i {
  @apply text-base cursor-pointer;
}

.spinner {
  @apply w-8 h-8 bg-cover animate-spin;
  background-image: url('./assets/spinner.svg');
}
</style>
