import { getAppVersionApi, getBulkImportApiVersionApi } from '@/services/actuator'
import { patchPropertyApi } from '@/services/properties'
import * as ss from '@/config/session-storage-help'
import { saveDefaultCryorobot } from '@/services/sites'
import toast from '@/config/toast'
import { CRYOROBOT } from '@/constants'

export default {
  async fetchApiVersion({ commit }) {
    try {
      commit('setAppInfoStatus', 'loading')
      const response = await getAppVersionApi()
      commit('fetchApiVersion', response)
      return response
    } catch (error) {
      commit('setAppInfoStatus', 'error')
      return error
    }
  },
  async fetchAppVersion({ commit }) {
    try {
      commit('setAppInfoStatus', 'loading')
      const response = await fetch('/meta.json', {
        method: 'GET',
        headers: {
          'Cache-Control': 'no-cache, no-store, must-revalidate',
          Pragma: 'no-cache',
          Expires: '0'
        }
      })
      const data = await response.json()
      commit('fetchAppVersion', data)
      return response
    } catch (error) {
      commit('setAppInfoStatus', 'error')
      return error
    }
  },
  async fetchBulkImportApiVersion({ commit }) {
    try {
      commit('setBulkImportApiStatus', 'loading')
      const response = await getBulkImportApiVersionApi()
      commit('fetchBulkImportApiVersion', response)
      return response
    } catch (error) {
      commit('setBulkImportApiStatus', 'error')
      return error
    }
  },
  async setModularCryolabel({ commit }, { property, data }) {
    try {
      commit('setModularCryolabelStatus', 'loading')
      const response = await patchPropertyApi({ property, data })
      commit('setModularCryolabel', { data, property })
      const existingSiteProperties = ss.getFieldSessionStorage('siteProperties')
      ss.setFieldSessionStorage('siteProperties', {
        ...existingSiteProperties,
        [property]: data.value
      })
      return response
    } catch (error) {
      commit('setModularCryolabelStatus', 'error')
      throw new Error(error.message)
    }
  },
  toggleAppInfoModal({ commit }, value = null) {
    commit('toggleAppInfoModal', value)
  },
  toggleTermsAndConditionsModal({ commit }, value = null) {
    commit('toggleTermsAndConditionsModal', value)
  },
  togglePrivacyPolicyModal({ commit }, value = null) {
    commit('togglePrivacyPolicyModal', value)
  },
  async setDefaultCryorobot({ commit, rootState }, value) {
    const {
      appBaseConfig: { clinicId, siteId },
      token
    } = rootState.authModule
    try {
      commit('setDefaultCryorobotStatus', 'loading')
      const response = await saveDefaultCryorobot({ clinicId, siteId, token, container: value })
      commit('setDefaultCryorobot', value)
      toast.success(`Default ${CRYOROBOT} updated`)
      return response
    } catch (error) {
      commit('setDefaultCryorobotStatus', 'error')
      toast.error({ title: error.message })
      throw new Error(error.message)
    }
  }
}
