import * as ss from '@/config/session-storage-help'
// Feature Flags
export const ADD_DOCUMENTS_ENABLED_FF = 'site.feature.add-documents.enabled'
export const CRYOLABEL_ENABLED_FF = 'site.feature.cryolabel.enabled'
export const MULTIPLE_PATIENTS_ENABLED_FF = 'site.feature.multiple-patients.enabled'
export const DONATE_TO_PATIENT_ENABLED_FF = 'site.feature.donate-to-patient.enabled'
export const DONATE_TO_RESEARCH_ENABLED_FF = 'site.feature.donate-to-research.enabled'
export const ADD_TO_EXISTING_BEACON_ENABLED_FF = 'site.feature.add-to-existing-beacon.enabled'
export const CRYOSHIPPER_ENABLED_FF = 'site.feature.cryoshipper.enabled'

// Values
export const TICKET_CRYOSHIPPER_SIZE_LIMIT = 'ticket.cryoshipper.small-shipment-size-limit'

export const isPropertyValid = (property, expectedValue) => {
  const siteProperties = ss.getFieldSessionStorage('siteProperties')
  return siteProperties && siteProperties[property] === expectedValue
}

export const isFeatureEnabled = (property) => isPropertyValid(property, 'true')

export const getSiteProperty = (property, defaultValue) => {
  return ss.getFieldSessionStorage('siteProperties', property) || defaultValue
}
