import { defineStore } from 'pinia'
import state from './state'
import { actions } from './actions'
import type { GlobalState, GlobalGetters, GlobalActions } from './types'

export const useGlobalStore = defineStore<
  'global',
  GlobalState,
  GlobalGetters,
  GlobalActions
>('global', {
  state,
  getters: {},
  actions,
  persist: {
    key: 'global',
    storage: window.sessionStorage
  }
})
