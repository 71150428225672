import moment from 'moment'
import getProp from 'lodash.get'

export const formatDate = (number) => {
  const timeFormated = moment.unix(number).format('h:mm a')
  return timeFormated
}

export const formatDateText = (text) => moment(text).format('DDMMMYYYY LT').toUpperCase()

export const formatDateDateOfBirth = (time) => moment(time).format('DDMMMYYYY').toUpperCase()
export const formatDateConcated = (time) => moment.unix(time).format('DDMMMYYYY').toUpperCase()

export const isValuePositive = (number) => number > 0

export const cutWord = (word, num) => word && word.slice(0, num)

export const interpolate = (value, targetObject) => {
  const newValue = value.replace(/\{\w+\}/g, (all) => {
    let itemKey = all.replace('{', '')
    itemKey = itemKey.replace('}', '')
    return targetObject[itemKey] || all
  })
  return newValue
}

export const selectEmbryoTypeLabel = (specimen, embryoTypes) => {
  if (embryoTypes && embryoTypes.length) {
    const [embryoType] = embryoTypes.filter(
      (embryo) =>
        specimen.embryo &&
        specimen.embryo.embryoType &&
        embryo.id === Number(specimen.embryo.embryoType)
    )
    return (embryoType && embryoType.name) || ''
  }
  return ''
}

export function isUUID(id) {
  return /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/.test(id)
}

export function formatFAIcon(iconString) {
  if (!iconString) {
    return ''
  }
  const regex = /-(regular|solid)$/
  let faClass = iconString
  let faStyle = 'fa'
  if (regex.test(faClass)) {
    const style = faClass.match(regex)[1]
    faStyle = style === 'solid' ? 'fas' : 'far'
    faClass = faClass.replace(regex, '')
  }
  return `${faStyle} fa-${faClass}`
}

export function sortSpecimensByCryodeviceId(ticket) {
  return ticket.map((cryobeacon) => {
    const specimensKey = cryobeacon.specimens ? 'specimens' : 'cryoDevice'
    return {
      ...cryobeacon,
      [specimensKey]: cryobeacon[specimensKey].sort(
        (a, b) => a.cryodeviceBarcode - b.cryodeviceBarcode
      )
    }
  })
}

export function sortByCryodevicesByBarcode({ cryodeviceBarcode: a }, { cryodeviceBarcode: b }) {
  if (a < b) {
    return -1
  }
  if (a > b) {
    return 1
  }
  return 0
}

export function trimString(str, maxLength = 15, ellipsisPosition = 'start', trimPart = 'end') {
  if (str?.length > maxLength) {
    const trimmedString =
      trimPart === 'end' ? str.substring(str.length - maxLength) : str.substring(0, maxLength)
    return ellipsisPosition === 'start' ? '...' + trimmedString : trimmedString + '...'
  }
  return str // if empty, returns empty, if null, returns null
}

export const dataIsArrayInArray = (options) => {
  return options && options.length && options[0].constructor === Array
}

export const sortOptions = ({ options: optionsOriginal, direction, orderBy, headers }) => {
  if (!optionsOriginal) {
    return []
  }
  const options = [...optionsOriginal] // clone to avoid modifying original
  let fieldToSort = headers.filter((header) => header.key === orderBy)

  let sorter = (a, b) => sortByProperty(a, b, orderBy, direction)

  if (
    fieldToSort &&
    fieldToSort.length &&
    fieldToSort[0].sortFn &&
    typeof fieldToSort[0].sortFn === 'object'
  ) {
    const { sortFn } = fieldToSort[0]
    sorter = direction === 'asc' ? sortFn.fieldAsc : sortFn.fieldDesc
  }
  if (dataIsArrayInArray(options)) {
    return options.map((item) => item.sort(sorter))
  }
  return options.sort(sorter)
}

const sortByProperty = (a, b, orderBy, direction) => {
  const nameA = getProp(direction === 'asc' ? a : b, orderBy, '')
  const nameB = getProp(direction === 'asc' ? b : a, orderBy, '')

  if (nameA && typeof nameA === 'string') {
    return nameA.toLowerCase().localeCompare(nameB.toLowerCase())
  }
  if (nameA < nameB) return -1
  if (nameA > nameB) return 1
  return 0
}

export const hasLatestVersion = async () => {
  if (!import.meta.env.PROD) {
    return true
  }
  const currentScripts = Array.from(document.head.querySelectorAll('script'))
  const currentMain = currentScripts.find((script) => script.src.includes('/assets/index'))
  try {
    const response = await fetch('/', {
      method: 'GET',
      headers: {
        'Cache-Control': 'no-cache, no-store, must-revalidate',
        Pragma: 'no-cache',
        Expires: '0'
      }
    })
    const html = await response.text()
    const parser = new DOMParser()
    const doc = parser.parseFromString(html, 'text/html') // Parse the HTML
    const scripts = Array.from(doc.head.querySelectorAll('script'))
    const main = scripts.find((script) => script.src.includes('/assets/index'))
    return main?.src === currentMain?.src
  } catch (e) {
    console.error('e', e)
  }
}
