<template>
  <section class="h-full overflow-y-scroll rounded-md bg-white px-1">
    <table class="w-full border bg-white px-2 border-collapse" data-test="table-row">
      <tbody>
        <tr v-if="headers.length" class="scroll-table-header" data-test="table-header">
          <th
            class="font-exo text-tmrw-blue z-10 pb-3 pt-4 p-1 whitespace-nowrap text-base sticky top-0 h-12 bg-white border-solid border-tmrw-blue px-3 w-24 max-w-none truncate overflow-hidden cursor-pointer border-b text-left"
          >
            &nbsp;
          </th>
          <th
            v-for="(item, index) in headers"
            :key="index"
            class="font-exo text-tmrw-blue z-10 pb-3 pt-4 p-1 whitespace-nowrap text-base sticky top-0 h-12 bg-white border-solid border-tmrw-blue px-3 max-w-none truncate overflow-hidden cursor-pointer border-b text-left"
            :class="[getColumnWidth(item)]"
            @click="emit('sortColumn', { key: item, toggle: true })"
          >
            {{ item }}
            <span
              class="inline-block relative w-3 h-3"
              :class="[
                {
                  'table-header__toggle-up': isColumnSorted(item, 'asc'),
                  'table-header__toggle-down': isColumnSorted(item, 'desc')
                }
              ]"
            >
            </span>
          </th>
        </tr>
        <tr v-if="procedure" data-test="batch-procedure">
          <td
            class="border-b border-solid border-tmrw-gray h-2 text-tmrw-blue text-left px-6 py-2 p-1 whitespace-nowrap text-sm font-bold"
          >
            &nbsp;
          </td>
          <td
            :colspan="headers.length"
            class="border-b border-solid border-tmrw-gray h-2 text-tmrw-blue text-left pt-8 pb-4 whitespace-nowrap text-sm font-bold pl-3"
          >
            {{ procedure }}
          </td>
        </tr>
        <tr
          v-for="(element, index) in apiElements"
          :key="index"
          class="scroll-table-row sm:h-auto sm:py-2 sm:px-0 border-solid border-tmrw-gray border-t cursor-pointer"
          :class="[{ 'selected-row': element[referenceKey].selected }]"
          data-test="tableComponent"
          :ref="setItemRef"
          @click="
            emit(
              'selectedColumn',
              element[referenceKey].value,
              element[screenigKey].optionalFlag,
              element[disabledKey].optionalFlag,
              element[procedureKey].value,
              $event,
              element[robotIdKey].value,
              element[robotLayoutTypeIdKey].value
            )
          "
          :disabled="element[referenceKey].disabled"
        >
          <td
            class="input-check font-inconsolata text-xl scroll-table-row__td py-5 font-medium border-b border-solid border-tmrw-gray sm:block md:table-cell sm:w-full md:w-auto sm:max-w-full sm:text-center px-3 td-date and time w-64 text-tmrw-blue-dark text-center"
          >
            <input
              type="checkbox"
              class="w-6 h-6 bg-white border block rounded-md border-solid relative border-tmrw-blue outline-none"
              :class="[
                {
                  'checkbox-container__checkbox--checked after:rotate-40 after:empty-content after:absolute after:w-2 after:h-3 after:border-r-4 after:border-b-4 after:border-solid after:border-tmrw-blue-dark after:top-40 after:left-50 after:transform after:-translate-x-1/2 after:-translate-y-1/2 after:rounded-sm':
                    element[referenceKey].selected
                }
              ]"
              :checked="element[referenceKey].selected"
            />
          </td>
          <template v-for="(item, i) in element">
            <td
              v-if="
                item.id !== procedureLabel &&
                item.id !== robotIdLabel &&
                item.id !== robotLayoutTypeId
              "
              :key="i"
              class="font-inconsolata text-xl scroll-table-row__td py-5 font-medium border-b border-solid border-tmrw-gray sm:block md:table-cell sm:w-full md:w-auto sm:max-w-full sm:text-left px-3 td-date and time w-64 text-left"
              :class="[
                { 'text-tmrw-blue-dark': !element[referenceKey].selected },
                { 'text-white font-bold': element[referenceKey].selected }
              ]"
            >
              <span v-html="item.value" />
            </td>
          </template>
        </tr>
      </tbody>
    </table>
  </section>
</template>
<script lang="ts" setup>
import { PROCEDURE, ROBOT_ID, ROBOT_LAYOUT_ID } from '@/constants'
import { onBeforeUpdate, ref, watch } from 'vue'

const props = defineProps([
  'headers',
  'procedure',
  'apiElements',
  'referenceKey',
  'screenigKey',
  'disabledKey',
  'procedureKey',
  'disabled',
  'robotIdKey',
  'robotLayoutTypeIdKey',
  'sort'
])

const emit = defineEmits(['sortColumn', 'selectedColumn'])

const itemRefs = ref<any[]>([])
const procedureLabel = ref(PROCEDURE)
const robotIdLabel = ref(ROBOT_ID)
const robotLayoutTypeId = ref(ROBOT_LAYOUT_ID)
const order = ref({
  direction: props.sort.direction,
  sortBy: props.sort.sortBy
})

const isColumnSorted = (column, direction) => {
  const columnKey = props.sort.getIdentifier(column)
  return columnKey === order.value.sortBy && order.value.direction === direction
}
const setItemRef = (el) => {
  if (el) {
    itemRefs.value.push(el)
  }
}

const getColumnWidth = (column) => {
  const columnKey = props.sort.getIdentifier(column)
  switch (columnKey) {
    case 'screeningStatus':
      return 'w-72'
    default:
      return 'w-48'
  }
}

watch(
  () => props.sort.sortBy,
  (sortBy) => {
    order.value = {
      ...order.value,
      sortBy
    }
  }
)
watch(
  () => props.sort.direction,
  (direction) => {
    order.value = {
      ...order.value,
      direction
    }
  }
)

onBeforeUpdate(() => {
  itemRefs.value = []
})
</script>

<style lang="scss" scoped>
.table-header {
  .table-header__th {
    @apply sticky;
    @apply text-sm whitespace-nowrap;
    @apply z-10 py-2 p-1 top-0 w-6 h-8 pl-5 pr-6;
    @apply border-solid border-tmrw-blue rounded-tl-md;
  }

  &__toggle-down,
  &__toggle-up {
    &:after {
      @apply top-0;
      @apply absolute;
      @apply empty-content;
      @apply ml-2 mt-1;
      @apply border-b-0;
      @apply border-t-5 border-solid border-tmrw-blue;
      @apply border-l-5 border-solid border-l-transparent;
      @apply border-r-5 border-solid border-r-transparent;
      @apply transform duration-100 ease-in-out;
    }
  }
  &__toggle-down:after {
    @apply rotate-0;
  }
  &__toggle-up:after {
    @apply rotate-180;
  }
  &__th--ellipsis {
    @apply max-w-0;
  }
}
</style>
