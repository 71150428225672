<template>
  <section data-test="login-form" class="login h-screen w-screen flex items-center justify-center">
    <div class="flex flex-row w-5/12">
      <div class="w-1/2 flex justify-center items-center">
        <image-element
          imageSrc="cryotransporter-banner-2.png"
          altText="CryoTransporter Image"
          width="80%"
        />
      </div>
      <div class="w-1/2 flex flex-col justify-center items-center">
        <tmrw-logo @onClickAppLogo="toggleLogo" class="cursor-pointer" />
        <p data-test="login-sub-title" class="text-tmrw-green-light text-center py-4">
          {{ $t('login.specimen_management_system') }}
        </p>
        <form
          @submit.prevent="onLoginSubmit"
          method="post"
          class="flex w-full flex-col justify-center"
        >
          <input-field
            inputType="text"
            :inputLabel="username ? $t('login.username') : ''"
            inputName="username"
            v-model="username"
            :borderColor="borderColor"
            placeholderColor="tmrw-green-light"
            labelColor="tmrw-green-light"
            inputColor="text-white"
            inputPlaceholder="User Name"
            class="w-full text-lg"
            addPaddingBottom
            hasFontInconsolata
            addOpacity
            :light-background="false"
            colorErrorText="text-tmrw-green-light"
          />
          <input-field
            :inputType="inputType"
            :inputLabel="password ? $t('login.password') : ''"
            inputName="password"
            v-model="password"
            :borderColor="borderColor"
            placeholderColor="tmrw-green-light"
            labelColor="tmrw-green-light"
            inputColor="text-white"
            inputPlaceholder="Password"
            class="mt-6 w-full text-lg"
            :hasError="loginError"
            addPaddingBottom
            hasFontInconsolata
            addOpacity
            :light-background="false"
            colorErrorText="text-tmrw-green-light"
          />

          <div class="flex items-center mt-8">
            <input
              type="checkbox"
              id="showPassword"
              v-model="showPassword"
              :class="{
                'w-5 h-5 border-gray-400 rounded': true,
                'bg-transparent': !showPassword,
                'bg-white': showPassword
              }"
              class="transparent-checkbox"
            />
            <label for="showPassword" class="ml-3 text-white text-lg">Show Password?</label>
            <!-- Label styles -->
          </div>

          <button-dynamic
            :btnText="$t('login.sign_in')"
            btnType="submit"
            btnStyle="primary"
            :isDisabled="username === '' || password === ''"
            :addClasses="['my-12']"
            floatTo="none"
            :isLoading="processingLogin"
          />
        </form>
        <button-dynamic
          btnType="button"
          :btnText="$t('login.forgot_password')"
          btnStyle="transparent"
          isBgTransparent
          addMarginBottom
          isTextUnderlined
          :addClasses="['mt-12']"
          @click="onClickForgot"
        />
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
import TmrwLogo from '@/components/TmrwLogo/TmrwLogo.vue'
import ImageElement from '@/components/ImageElement/ImageElement.vue'
import InputField from '@/components/InputField/InputField.vue'
import ButtonDynamic from '@/components/ButtonDynamic/ButtonDynamic.vue'
import { RESETED_PASSWORD_SUCCESSFULLY } from '@/constants'
import toast from '@/config/toast'
import { computed, inject, onMounted, ref, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import useGetters from '@/composables/useGetters'
import useActions from '@/composables/useActions'
import { hasLatestVersion } from '@/utils'
import { useGlobalStore } from '@/store/global'

const route = useRoute()
const router = useRouter()

const username = ref('')
const loginError = ref<string | boolean>('')
const password = ref('')
const showPassword = ref(false)
const processingLogin = ref(false)

const mixpanel = inject<any>('mixpanel')

const { isLoggedIn, appBaseConfig, loggedUserInfo } = useGetters('authModule')
const borderColor = computed(() => {
  return loginError.value ? 'tmrw-danger' : 'white'
})
const inputType = computed(() => {
  return showPassword.value ? 'text' : 'password'
})

const { fetchLogin } = useActions('authModule')
const { hideSpinner } = useActions('spinnerModule')
const { toggleAppInfoModal } = useActions('appInfoModule')
const { resetStateFilterTabs } = useActions('ticketsModule')

const globalStore = useGlobalStore()
const onClickForgot = () => {
  router.push('/forgot-password')
}
const onLoginSubmit = async () => {
  try {
    processingLogin.value = true

    await fetchLogin({
      username: username.value,
      password: password.value
    })
    mixpanel.identify(loggedUserInfo.value.userId.toString())
    mixpanel.people.set({
      'first-name': loggedUserInfo.value.firstName,
      'last-name': loggedUserInfo.value.lastName,
      'clinic-name': appBaseConfig.value.clinicName,
      'site-name': appBaseConfig.value.siteName,
      'user-name': loggedUserInfo.value.loginName,
      'user-type': loggedUserInfo.value.userType,
      // eslint-disable-next-line quote-props
      $name: `${loggedUserInfo.value.firstName} ${loggedUserInfo.value.lastName}`
    })
    mixpanel.people.increment('Total Logins')
    const latestVersion = await hasLatestVersion()
    if (latestVersion) {
      router.push('/flight-board')
    } else {
      globalStore.showAppUpdated(true)
      window.location.reload()
    }
  } catch (error: any) {
    loginError.value = error.response?.data[0]?.message || error.message
  } finally {
    processingLogin.value = false
  }
}
const toggleLogo = () => {
  toggleAppInfoModal()
}

onMounted(() => {
  if (route?.params?.resetedPassword) {
    toast.success(RESETED_PASSWORD_SUCCESSFULLY)
  }
  resetStateFilterTabs()
  hideSpinner()
  if (isLoggedIn.value) {
    router.push('/flight-board')
  }
})

watch(username, () => {
  loginError.value = false
})
watch(password, () => {
  loginError.value = false
})
</script>

<style lang="scss">
.login {
  background: url('../../assets/circles-background.svg') center center repeat-x;
  background-size: 50% auto;
}
.transparent-checkbox {
  @apply border border-white outline-none cursor-pointer bg-transparent;
  appearance: none;
  -webkit-appearance: none;
}

.transparent-checkbox:checked {
  @apply bg-white relative;
}

.transparent-checkbox:checked::before {
  @apply absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-black;
  content: '\2714';
}
</style>
