export default {
  setAppInfoStatus(state, value) {
    state.appInfoStatus = value
  },
  setBulkImportApiStatus(state, value) {
    state.bulkImportApiStatus = value
  },
  fetchApiVersion(state, response) {
    state.apiVersion = response.data.app.version
  },
  fetchAppVersion(state, data) {
    state.appVersion = data.version
  },
  fetchBulkImportApiVersion(state, response) {
    state.bulkImportApiVersion = response.data.app.version
  },
  toggleAppInfoModal(state, value = null) {
    // ** If there is no value, it works as a normal toggle,
    // * changing the state to the opposite, otherwise it takes the parameter is passed
    state.isAppModalOpen = value === null ? !state.isAppModalOpen : value
  },
  setModularCryolabelStatus(state, value) {
    state.modularCryolabelStatus = value
  },
  setModularCryolabel(state, { data, property }) {
    state.modularCryolabel[property] = data.value
  },
  toggleTermsAndConditionsModal(state, value = null) {
    // ** If there is no value, it works as a normal toggle,
    // * changing the state to the opposite, otherwise it takes the parameter is passed
    state.isTermsAndConditionsModalOpen =
      value === null ? !state.isTermsAndConditionsModalOpen : value
  },
  togglePrivacyPolicyModal(state, value = null) {
    // ** If there is no value, it works as a normal toggle,
    // * changing the state to the opposite, otherwise it takes the parameter is passed
    state.isPrivacyPolicyModalOpen = value === null ? !state.isPrivacyPolicyModalOpen : value
  },
  setDefaultCryorobotStatus(state, value) {
    state.defaultCryorobotStatus = value
  },
  setDefaultCryorobot(state, value) {
    state.defaultCryorobot = value
  }
}
