<template>
  <section data-test="ticket-card-detail" class="ticket-card pt-6 px-6 pb-6 bg-white rounded-lg">
    <!-- Header -->
    <header data-test="ticket-card-detail__header">
      <!-- Title -->
      <div class="grid grid-cols-12 relative">
        <div class="col-span-2 items-end border">
          <img
            data-test="tmrw-ticket-logo"
            src="@/assets/tmrw-ticket-logo.svg"
            class="h-8 mr-28 mb-2"
            alt="tmrw-ticket-logo"
          />
        </div>
        <div
          class="col-span-2 border-solid border-r-2 border-tmrw-gray pl-10 pr-5 flex flex-col items-start justify-start"
          v-if="!isCryoShipperTicket"
        >
          <div data-test="patient-name">
            <p class="text-xs font-bold text-tmrw-blue">PATIENT NAME</p>
            <div class="flex flex-row">
              <p
                data-test="ticket-card-detail__patient-name"
                class="font-bold text-3xl mr-2 break-all"
              >
                {{ patientData?.firstName }} {{ patientData?.lastName }}
              </p>
              <div
                v-if="isMultiplePatientsEnabled && multiplePatientsTooltipCount"
                class="relative h-content"
              >
                <span
                  data-test="multiple-patient-tooltip-label"
                  @mouseover="toggleMultiplePatientsTooltipVisibility"
                  @mouseleave="toggleMultiplePatientsTooltipVisibility"
                  class="rounded-md p-1 px-2 border border-solid border-tmrw-blue text-tmrw-blue font-bold text-xs cursor-pointer bottom-0"
                >
                  +{{ multiplePatientsTooltipCount }}
                </span>
                <tooltip v-if="showMultiplePatientsTooltip" :content="sharedPatientsList" />
              </div>
            </div>
          </div>
        </div>
        <div class="col-span-7 grid grid-cols-12">
          <div class="col-span-12 grid grid-cols-12">
            <div class="flex flex-col mx-6 col-span-3">
              <span class="text-xs font-bold text-tmrw-blue mb-2">TICKET TYPE</span>
              <span
                data-test="ticket-card-detail__ticket-type"
                class="font-semibold text-lg capitalize"
              >
                {{ transformedTitle }}
              </span>
            </div>
            <div class="flex flex-col mx-6 col-span-3">
              <span class="text-xs font-bold text-tmrw-blue mb-2">TICKET NUMBER</span>
              <span data-test="created-ticket-id" class="font-semibold text-lg">{{
                ticketId || refTicketId || '--'
              }}</span>
            </div>
            <div class="flex flex-col mx-6 col-span-3" v-if="!isCryoShipperTicket">
              <span class="text-xs font-bold text-tmrw-blue mb-2">PATIENT ID</span>
              <span data-test="ticket-card-detail__patient-id" class="font-semibold text-lg">
                {{ patientData?.identificationNumber || 'No info' }}
              </span>
            </div>
            <div class="flex flex-col mx-6 col-span-3" v-if="!isCryoShipperTicket">
              <span class="text-xs font-bold text-tmrw-blue mb-2">CLINIC</span>
              <span class="font-semibold text-lg" data-test="ticket-card-detail__clinic-name">{{
                appBaseConfig.siteName
              }}</span>
            </div>
            <div class="flex flex-col mx-6 col-span-3" v-if="isCryoShipperTicket">
              <span class="text-xs font-bold text-tmrw-blue mb-2">ASSOCIATED CRYOSHIPPER ID</span>
              <span
                v-if="!isCryoShipperLabelTooLong"
                class="font-semibold text-lg"
                data-test="ticket-card-detail__clinic-name"
                >{{ shipmentDetails?.cryoshipperLabel || '---' }}</span
              >
              <span
                v-else
                class="font-semibold text-lg"
                data-test="ticket-card-detail__clinic-name"
              >
                <span>{{
                  shipmentDetails?.cryoshipperLabel?.slice(0, MAX_CHARACTERS_IN_ASSOCIATED_ID_LINE)
                }}</span
                ><br
                  v-if="
                    shipmentDetails?.cryoshipperLabel.length > MAX_CHARACTERS_IN_ASSOCIATED_ID_LINE
                  "
                />
                <span
                  v-if="
                    shipmentDetails?.cryoshipperLabel.length > MAX_CHARACTERS_IN_ASSOCIATED_ID_LINE
                  "
                  >{{
                    shipmentDetails?.cryoshipperLabel?.slice(
                      MAX_CHARACTERS_IN_ASSOCIATED_ID_LINE,
                      MAX_CHARACTERS_IN_ASSOCIATED_ID_LINE * 2
                    )
                  }}</span
                ><br
                  v-if="
                    shipmentDetails?.cryoshipperLabel.length >
                    MAX_CHARACTERS_IN_ASSOCIATED_ID_LINE * 2
                  "
                />
                <span
                  v-if="
                    shipmentDetails?.cryoshipperLabel.length >
                    MAX_CHARACTERS_IN_ASSOCIATED_ID_LINE * 2
                  "
                  >{{
                    shipmentDetails?.cryoshipperLabel?.slice(
                      MAX_CHARACTERS_IN_ASSOCIATED_ID_LINE * 2,
                      MAX_CHARACTERS_IN_ASSOCIATED_ID_LINE * 3
                    )
                  }}</span
                ><br
                  v-if="
                    shipmentDetails?.cryoshipperLabel.length >
                    MAX_CHARACTERS_IN_ASSOCIATED_ID_LINE * 3
                  "
                />
                <span
                  v-if="
                    shipmentDetails?.cryoshipperLabel.length >
                    MAX_CHARACTERS_IN_ASSOCIATED_ID_LINE * 3
                  "
                  >{{
                    shipmentDetails?.cryoshipperLabel?.slice(
                      MAX_CHARACTERS_IN_ASSOCIATED_ID_LINE * 3,
                      MAX_CHARACTERS_IN_ASSOCIATED_ID_LINE * 4
                    )
                  }}</span
                >
              </span>
            </div>
            <div class="flex flex-col mx-6 col-span-3" v-if="isCryoShipperTicket">
              <span class="text-xs font-bold text-tmrw-blue mb-2">CRYOSHIPPER FORMAT</span>
              <span class="font-semibold text-lg" data-test="ticket-card-detail__clinic-name">{{
                cryoShipperFormat
              }}</span>
            </div>
          </div>
        </div>
        <div
          v-if="isEMRTicket"
          class="flex items-start justify-end flex-row absolute top-0 right-0"
          data-test="ticket-card__emr-ticket-label"
        >
          <img src="@/assets/EMR-light-blue.svg" class="w-6" />
          <span class="text-tmrw-blue-light mt-1">EMR</span>
        </div>
        <div
          v-if="isCryoShipperTicket"
          class="flex items-start justify-end flex-row absolute top-0 right-0"
          data-test="ticket-card__cryoshipper-visbile-toggle"
        >
          <CryoShipperVisibleToggle
            v-if="isCryoShipperToggleVisible"
            :retrieve-state="shipmentDetails?.retrieveState"
            :shipment-id="shipmentDetails?.shipmentId"
          />
        </div>
      </div>
    </header>
    <!-- Slot List Table -->
    <slot v-if="thereIsList" name="list" />
  </section>
</template>

<script setup lang="ts">
import Tooltip from '@/components/Tooltip/Tooltip.vue'
import CryoShipperVisibleToggle from '@/components/Biorepository/CryoShipperVisibleToggle/CryoShipperVisibleToggle.vue'
import {
  TRANSFER_OUT_VALUE,
  DISCARD_VALUE,
  DONATION_UPPERCASE,
  OTHER_VALUE,
  TRANSFER_OUT,
  DISCARD,
  DONATION,
  OTHER,
  DONATION_TO_RESEARCH,
  DONATE_TO_RESEARCH_VALUE,
  HAND_CARRY,
  SHIPPING_VALUE,
  SHIPPING,
  MAX_CHARACTERS_IN_ASSOCIATED_ID_LINE,
  CRYOSHIPPER_SMALL,
  SMALL_CRYOSHIPPER_SIZE,
  MEDIUM_CRYOSHIPPER_FULL_SIZE
} from '@/constants'
import {
  getSiteProperty,
  isFeatureEnabled,
  MULTIPLE_PATIENTS_ENABLED_FF,
  TICKET_CRYOSHIPPER_SIZE_LIMIT
} from '@/helpers/featureFlags'

import { fetchPatientsById } from '@/services/patients'
import { mountMultiplePatientsStringList } from '@/helpers/multiplePatients/mountMultiplePatientsStringList'
import { computed, onMounted, ref, useSlots, watch } from 'vue'
import useGetters from '@/composables/useGetters'

const groupedExportReasons = {
  TRANSFER_OUT_VALUE,
  DISCARD_VALUE,
  DONATION_UPPERCASE,
  OTHER_VALUE,
  TRANSFER_OUT,
  DISCARD,
  DONATION,
  OTHER,
  DONATION_TO_RESEARCH,
  HAND_CARRY,
  SHIPPING_VALUE,
  SHIPPING
}

const multiplePatientsTooltipCount = ref(0)
const showMultiplePatientsTooltip = ref(false)
const isMultiplePatientsEnabled = ref(false)
const sharedPatientsList = ref('')

const smallCryoShipperSize = ref(
  getSiteProperty(TICKET_CRYOSHIPPER_SIZE_LIMIT, SMALL_CRYOSHIPPER_SIZE)
)

const props = defineProps({
  title: {
    type: String,
    required: true
  },
  patientPic: {
    type: String,
    required: false
  },
  patientData: {
    type: Object,
    required: false
  },
  sharedPatientIds: {
    type: Array,
    required: false,
    default: () => []
  },
  ticketId: {
    type: [String, Number, Boolean],
    required: false
  },
  qrCode: {
    type: String,
    required: false
  },
  editDate: {
    type: Boolean,
    required: false,
    default: false
  },
  robotTransfer: {
    type: String,
    required: false
  },
  isErasable: {
    type: Boolean,
    required: false,
    default: false
  },
  editSpecimens: {
    type: Boolean,
    required: false
  },
  deleteButton: {
    type: [String, Boolean],
    required: false,
    default: false
  },
  isExport: {
    type: [Object, Boolean],
    required: false
  },
  refTicketId: {
    type: [String, Number, Boolean],
    required: false
  },
  isEMRTicket: {
    type: Boolean,
    default: false
  },
  ticket: {
    type: Object,
    required: false
  },
  sharedPatients: {
    type: Array,
    required: false,
    default: () => []
  },
  isCryoShipperTicket: {
    type: Boolean,
    required: false,
    default: false
  },
  shipmentDetails: {
    type: Object,
    required: false,
    default: () => null
  }
})

const slots = useSlots()

const { appBaseConfig } = useGetters('authModule')

const thereIsList = computed(() => {
  return 'list' in slots
})
const isCryoShipperToggleVisible = computed(() => {
  return props.robotTransfer !== 'IN'
})

const cryoShipperFormat = computed(() => {
  const limit =
    props.shipmentDetails?.shipmentType === CRYOSHIPPER_SMALL
      ? smallCryoShipperSize.value
      : MEDIUM_CRYOSHIPPER_FULL_SIZE
  return `${limit} CryoBeacon Limit`
})

const isCryoShipperLabelTooLong = computed(() => {
  return props.shipmentDetails?.cryoshipperLabel?.length > MAX_CHARACTERS_IN_ASSOCIATED_ID_LINE
})

const getExportReason = ({ subType, type }: any) => {
  if (subType === DONATE_TO_RESEARCH_VALUE) return 'Export for Research'
  return `Export for ${groupedExportReasons[type]}`
}
const toggleMultiplePatientsTooltipVisibility = () => {
  showMultiplePatientsTooltip.value = !showMultiplePatientsTooltip.value
}

const transformedTitle = computed(() => {
  let title =
    props.isExport && (props.isExport as any).display
      ? getExportReason(props.isExport).toLowerCase()
      : props.title.toLowerCase()

  return title === 'cryoshipper' ? 'CryoShipper' : title
})

const fetchSharedPatientsList = async (ids) => {
  sharedPatientsList.value = ''
  multiplePatientsTooltipCount.value = 0
  if (!isMultiplePatientsEnabled.value) {
    return
  }
  try {
    const sharedPatients = (await fetchPatientsById(ids)).map((patient) => ({
      ...patient,
      fullName: `${patient.firstName} ${patient.lastName}`
    }))
    setSharedPatients(sharedPatients)
  } catch (e) {
    // ERROR
  }
}
const setSharedPatients = (patients) => {
  if (!isMultiplePatientsEnabled.value || !patients.length) {
    return
  }
  multiplePatientsTooltipCount.value = patients.length
  sharedPatientsList.value = mountMultiplePatientsStringList(patients)
}

onMounted(() => {
  isMultiplePatientsEnabled.value = isFeatureEnabled(MULTIPLE_PATIENTS_ENABLED_FF)
  if (props.sharedPatients.length) {
    // SET Patients without fetching
    setSharedPatients(props.sharedPatients)
  } else {
    // Only Ids, fetching patients
    fetchSharedPatientsList(props.sharedPatientIds)
  }
})

watch(
  () => props.sharedPatientIds,
  (newIds) => {
    fetchSharedPatientsList(newIds)
  }
)
watch(
  () => props.sharedPatients,
  (newPatients) => {
    setSharedPatients(newPatients)
  }
)
</script>

<style lang="scss">
.ticket-card {
  background: white url('../../assets/circles-background.svg') top right no-repeat;
  background-size: 50% auto;
}
</style>
