import axios from 'axios'
import dayjs from 'dayjs'
import * as Sentry from '@sentry/vue'
import * as ss from '@/config/session-storage-help'
import siteInfo from '@/config/site'
import { getPropertyApi } from '@/services/properties'
import { postSessionApi, postRenewSessionApi, deleteSessionApi } from '@/services/sessions'
import { getClinicInfoApi } from '@/services/clinics'
import { getSiteInfoApi } from '@/services/sites'
import {
  CRYOLABEL_ENABLED_FF,
  MULTIPLE_PATIENTS_ENABLED_FF,
  DONATE_TO_PATIENT_ENABLED_FF,
  DONATE_TO_RESEARCH_ENABLED_FF,
  ADD_TO_EXISTING_BEACON_ENABLED_FF,
  CRYOSHIPPER_ENABLED_FF,
  TICKET_CRYOSHIPPER_SIZE_LIMIT
} from '@/helpers/featureFlags'
import { TOKEN_EXPIRATION_IN_MINUTES } from '@/constants'

const $ss = ss

function setTokenExpiration(commit) {
  const tokenExpiration = dayjs().add(TOKEN_EXPIRATION_IN_MINUTES, 'minute')
  $ss.setFieldSessionStorage('tokenExpiresAt', tokenExpiration, 'user')
  commit('setTokenExpiresAt', tokenExpiration)
}

export default {
  async fetchLogin({ commit, dispatch }, credentials) {
    // eslint-disable-next-line no-useless-catch
    try {
      // Fetch session info
      const sessionResponse = await postSessionApi({ ...credentials })
      const {
        token,
        firstName,
        lastName,
        loginName,
        siteId,
        clinicId,
        site,
        clinic,
        collectionProtocolId,
        id: userId,
        userType,
        permissions
      } = sessionResponse.data
      Sentry.setUser({
        username: loginName,
        id: userId
      })
      Sentry.setTag('siteId', site.id)
      Sentry.setTag('siteName', site.name)
      Sentry.setTag('clinicId', clinic.id)
      Sentry.setTag('clinicName', clinic.name)
      Sentry.setTag('unitId', site.unitIds[0])

      $ss.setFieldSessionStorage('token', token, 'user')
      $ss.setFieldSessionStorage('firstName', firstName, 'user')
      $ss.setFieldSessionStorage('lastName', lastName, 'user')
      $ss.setFieldSessionStorage('userName', loginName, 'user')
      $ss.setFieldSessionStorage('collectionProtocolId', collectionProtocolId, 'config')
      $ss.setFieldSessionStorage('userId', userId, 'user')
      $ss.setFieldSessionStorage('userType', userType, 'user')
      $ss.setFieldSessionStorage('permissions', permissions, 'user')
      $ss.setFieldSessionStorage('biorepoConfig', site.biorepoConfig, 'config')
      const siteProperties = site.properties || {}
      $ss.setFieldSessionStorage('siteProperties', siteProperties)

      const setSiteProperty = async (property) => {
        if (!siteProperties[property]) {
          const { data } = await getPropertyApi({ property, clinicId, siteId })
          const existingSiteProperties = $ss.getFieldSessionStorage('siteProperties')
          $ss.setFieldSessionStorage('siteProperties', { ...existingSiteProperties, ...data })
        }
      }

      setSiteProperty('ticket.cryodevice.max-number')
      setSiteProperty('site.feature.cryolabel.enabled')
      setSiteProperty(CRYOLABEL_ENABLED_FF)
      setSiteProperty(MULTIPLE_PATIENTS_ENABLED_FF)
      setSiteProperty('site.cryolabel.embryo')
      setSiteProperty('site.cryolabel.egg')
      setSiteProperty('causeway.clinic-setup.label-printers-ids')
      setSiteProperty('causeway.clinic-setup.ticket-printers-ids')
      setSiteProperty(DONATE_TO_PATIENT_ENABLED_FF)
      setSiteProperty(DONATE_TO_RESEARCH_ENABLED_FF)
      setSiteProperty(ADD_TO_EXISTING_BEACON_ENABLED_FF)
      setSiteProperty(CRYOSHIPPER_ENABLED_FF)
      setSiteProperty(TICKET_CRYOSHIPPER_SIZE_LIMIT)

      // Fetch site info
      const getSiteInfo = await getSiteInfoApi({ clinicId, siteId, token })
      const {
        data: { name: siteName, emrIntegration, unitIds }
      } = getSiteInfo

      $ss.setFieldSessionStorage('siteId', siteId, 'config')
      $ss.setFieldSessionStorage('siteName', siteName, 'config')
      $ss.setFieldSessionStorage('unitIds', unitIds, 'config')
      $ss.setFieldSessionStorage('emrIntegration', emrIntegration || false, 'config')

      // Fetch clinic info
      const {
        data: { name: clinicName }
      } = await getClinicInfoApi({ clinicId, token })

      $ss.setFieldSessionStorage('clinicId', clinicId, 'config')
      $ss.setFieldSessionStorage('clinicName', clinicName, 'config')

      const loggedUserInfo = {
        loginName,
        userType,
        userId,
        permissions,
        firstName,
        lastName
      }
      const appBaseConfig = {
        clinicId,
        clinicName,
        siteId,
        siteName,
        collectionProtocolId,
        unitIds
      }
      commit('fetchLogin', { token, loggedUserInfo, appBaseConfig })
      await dispatch(
        'siteModule/getAllContainers',
        { includeTemporary: false, includeNonTmrw: true },
        { root: true }
      )
      await dispatch('siteModule/getAllSites', null, { root: true })
      setTokenExpiration(commit)
    } catch (err) {
      throw err
    }
  },
  async logout({ commit }, { resetTicketProcess, router }) {
    const reset = () => {
      commit('logout')
      resetTicketProcess()
      router.push('/')
    }
    try {
      const { headers } = siteInfo()
      deleteSessionApi({ headers })
      // do not wait for session response
      delete axios.defaults.headers.common.Authorization
      Sentry.setUser(null)
      return reset()
    } catch (error) {
      return reset(), error
    }
  },

  async renewToken({ commit }) {
    try {
      await postRenewSessionApi()
      setTokenExpiration(commit)
    } catch (error) {
      throw new Error(error.message)
    }
  }
}
