<template>
  <section class="p-4" data-test="patient-view">
    <top-header>{{ pageTitle }}</top-header>
    <loading-ui v-if="loadingPatientData" modal message="Loading Patient Data" />
    <ActionBar data-test="patient-view__action-bar" colsDistribution="6/6" class="mt-4">
      <template v-slot:left-side>
        <dynamic-title titleType="h2" floatTo="left"> Patient Details </dynamic-title>
        <p
          data-test="emr-patient-icon-label"
          v-if="!allowCreatePatients"
          class="flex ml-4 float-left text-tmrw-blue-light h-6"
        >
          <i data-test="emr-lock-icon" class="fa fa-lock text-xl mr-2 mt-0.5" />
          <span class="font-medium text-2xl">EMR</span>
        </p>
      </template>
      <template v-slot:right-side>
        <button-dynamic
          btnType="button"
          btnText="Close"
          btnStyle="secondary"
          addMarginRight
          @click="closePatient"
        />
        <button-dynamic
          v-if="allowCreatePatients"
          btnText="Edit"
          btnType="button"
          btnStyle="secondary"
          showIcon
          :isDisabled="!allowCreatePatients"
          fontAwesomeIconClass="edit"
          @click="updatePatient"
        />
      </template>
    </ActionBar>
    <div
      v-if="Object.keys(patient).length"
      data-test="patient-view__patient-details"
      data-testid="patient-detail"
      class="grid gap-4 p-4 grid-cols-1 sm:grid-cols-2 md:grid-cols-4 lg:grid-cols-4 xl:grid-cols-4 bg-white rounded-md mt-2"
    >
      <div
        :key="key"
        v-for="(field, key) in patientFields"
        :class="field.addClasses && [...field.addClasses]"
      >
        <span class="text-xs font-normal text-tmrw-blue">
          {{ field.label }}
        </span>
        <p
          class="text-xl font-medium text-tmrw-blue-dark border-b border-dotted border-gray-800 py-2"
          :data-test="`patient-field-${field.dataTest}`"
          :class="field.valueAddClasses && [...field.valueAddClasses]"
        >
          {{ field.value }}
        </p>
      </div>
    </div>
    <PatientDocuments class="mt-6 mb-4" />
    <dynamic-title v-if="!loadingPatientData" titleType="h3" floatTo="none" class="mt-6 mb-4">
      {{ numberTickets }} Open Ticket{{ numberTickets === 1 ? '' : 's' }}
    </dynamic-title>
    <!-- @vue-ignore -->
    <ScrollTableComponent
      data-test="patient-view__open-tickets-table"
      data-testid="patient-open-tickets"
      v-if="numberTickets && !loadingPatientData"
      v-model="localTicketsModel"
      :headers="headersTickets"
      minHeight="h-auto"
      :overflowHidden="false"
      hideSelectAllCheckbox
    />
    <div v-if="!loadingPatientData" class="patient text-white w-full">
      <dynamic-title titleType="h3" floatTo="none" class="mt-6 mb-4">
        {{ numberOfActiveSpecimens.totalCount }}
        {{
          numberOfActiveSpecimens.totalCount === 1 ? SPECIMEN_CAPITALIZED : SPECIMENS_CAPITALIZED
        }}
        in Inventory
      </dynamic-title>
      <ActionBar
        v-if="numberOfActiveSpecimens.totalCount !== 0"
        data-test="patient-view__action-bar"
        colsDistribution="6/6"
      >
        <template v-slot:left-side>
          <Tabs
            :options="activeSpecimensOptions"
            :active="activeSpecimensTab"
            @changeValue="handleTabChange"
          />
        </template>
        <template v-slot:right-side>
          <button-dynamic
            v-if="numberOfActiveSpecimens.hasSpecimens"
            class="float-right"
            btnText="Update"
            btnType="button"
            btnStyle="secondary"
            showIcon
            addMarginBottom
            fontAwesomeIconClass="edit"
            @click="handlePatientInventoryRedirect"
          />
        </template>
      </ActionBar>
      <!-- @vue-ignore -->
      <ScrollTableComponent
        data-testid="patient-active-tickets"
        data-test="patient-view__inventory-table"
        v-if="numberOfActiveSpecimens.hasSpecimens"
        v-model="localSpecimensModel"
        :headers="headers"
        minHeight="h-auto"
        :overflowHidden="false"
        hasTabs
      />

      <dynamic-title
        titleType="h3"
        floatTo="none"
        class="mt-6 mb-4"
        v-if="numberOfMissingSpecimens.totalCount"
      >
        {{ numberOfMissingSpecimens.totalCount }}
        {{
          numberOfMissingSpecimens.totalCount === 1
            ? ` Missing ${SPECIMEN_CAPITALIZED}`
            : `Missing ${SPECIMENS_CAPITALIZED}`
        }}
      </dynamic-title>
      <ActionBar data-test="patient-view__action-bar" colsDistribution="6/6">
        <template v-slot:left-side>
          <Tabs
            v-if="numberOfMissingSpecimens.totalCount"
            :options="missingSpecimensOptions"
            :active="missingSpecimensTab"
            @changeValue="handleMissingTabChange"
          />
        </template>
        <template v-slot:right-side>
          <button-dynamic
            v-if="numberOfMissingSpecimens.hasSpecimens"
            class="float-right"
            btnText="Update"
            btnType="button"
            btnStyle="secondary"
            showIcon
            addMarginBottom
            fontAwesomeIconClass="edit"
            @click="handlePatientInventoryRedirect"
          />
        </template>
      </ActionBar>
      <!-- @vue-ignore -->
      <ScrollTableComponent
        data-testid="patient-missing-tickets"
        data-test="patient-view__missing-inventory-table"
        v-model="localMissingSpecimensModel"
        v-if="numberOfMissingSpecimens.totalCount"
        :headers="headersMissing"
        minHeight="h-auto"
        :overflowHidden="false"
        hasTabs
      />

      <dynamic-title
        v-if="numberOfOffsiteSpecimens.totalCount"
        class="mt-6 mb-4"
        floatTo="none"
        titleType="h3"
      >
        {{ subtitleOffsiteSpecimens }}
      </dynamic-title>

      <div v-if="numberOfOffsiteSpecimens.totalCount" class="flex justify-between">
        <Tabs
          :options="offsiteSpecimensOptions"
          :active="filterOffsite"
          @changeValue="handleOffsiteTabChange"
        />
      </div>
      <!-- @vue-ignore -->
      <ScrollTableComponent
        data-testid="patient-offsite-tickets"
        data-test="patient-view__offsite-inventory-table"
        class="mb-12"
        v-if="numberOfOffsiteSpecimens.totalCount"
        v-model="localOffsiteSpecimensModel"
        :headers="headersOffSite"
        minHeight="h-auto"
        :overflowHidden="false"
        hasTabs
      />

      <dynamic-title
        titleType="h3"
        floatTo="none"
        class="mt-6 mb-4"
        v-if="numberOfClosedSpecimens.totalCount"
      >
        {{ numberOfClosedSpecimens.totalCount }}
        {{
          numberOfClosedSpecimens.totalCount === 1 ? SPECIMEN_CAPITALIZED : SPECIMENS_CAPITALIZED
        }}
        No Longer in Inventory
      </dynamic-title>
      <div v-if="numberOfClosedSpecimens.totalCount" class="flex justify-between">
        <Tabs
          :options="closedSpecimensOptions"
          :active="closedSpecimensTab"
          @changeValue="handleClosedTabChange"
        />
      </div>
      <!-- @vue-ignore -->
      <ScrollTableComponent
        data-testid="patient-closed-tickets"
        data-test="patient-view__closed-inventory-table"
        class="mb-12"
        v-if="numberOfClosedSpecimens.totalCount"
        v-model="localClosedSpecimensModel"
        :headers="headersClosed"
        minHeight="h-auto"
        :overflowHidden="false"
        hasTabs
      />
    </div>
  </section>
</template>

<script setup lang="ts">
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import inProgress from '@/assets/ticketState/in-progress.svg'
import {
  CRYODEVICES,
  SPECIMEN_TYPE_EMBRYO,
  SPECIMEN_TYPE_EGG,
  SPECIMEN_CAPITALIZED,
  SPECIMENS_CAPITALIZED,
  INFECTIOUS_SCREENING_STATUS,
  MISSING_CRYOBEACON,
  TEMPORARY,
  CURRENT_LOCATION
} from '@/constants'
import * as patientDetailTableHeaders from '@/constants/table-headers/patient/details'
import ActionBar from '@/components/ActionBar/ActionBar.vue'
import ButtonDynamic from '@/components/ButtonDynamic/ButtonDynamic.vue'
import DynamicTitle from '@/components/DynamicTitle/DynamicTitle.vue'
import LoadingUi from '@/components/LoadingUi/LoadingUi.vue'
import ScrollTableComponent from '@/components/ScrollTableComponent/ScrollTableComponent.vue'
import Tabs from '@/components/Tabs/Tabs.vue'
import TopHeader from '@/components/TopHeader/TopHeader.vue'
import dateAndTime from '@/helpers/dateAndTime'
import PatientDocuments from '@/components/Documents/PatientDocuments.vue'
import { selectEmbryoTypeLabel } from '@/utils'
import toast from '@/config/toast'
import * as ss from '@/config/session-storage-help'
import { checkSpecimenImport } from '@/helpers/patientHelpers'
import { replaceStringFilter } from '@/filters'
import { computed, inject, onBeforeUnmount, onMounted, ref, watch } from 'vue'
import useGetters from '@/composables/useGetters'
import useActions from '@/composables/useActions'
import { useRoute, useRouter } from 'vue-router'

dayjs.extend(utc)

export type NumberOfSpecimens = {
  embryoCount: number
  oocyteCount: number
  totalCount: number
  hasSpecimens?: boolean
}

type PatientField = {
  label: string
  value: any
  dataTest: string
  addClasses?: string[]
  valueAddClasses?: string[]
}

const route = useRoute()
const router = useRouter()

const isSiteEmrEnabled = inject<any>('isSiteEmrEnabled')

const specimensModal = {
  sort: {
    orderBy: 'cryoDate',
    direction: 'desc'
  },
  options: []
}

const activeSpecimensTab = ref(SPECIMEN_TYPE_EMBRYO)
const closedSpecimensTab = ref(SPECIMEN_TYPE_EMBRYO)
const missingSpecimensTab = ref(SPECIMEN_TYPE_EMBRYO)
const filterOffsite = ref(SPECIMEN_TYPE_EMBRYO)
const allowCreatePatients = ref(isSiteEmrEnabled?.allowCreatePatients)
const loadingPatientData = ref(true)
const localSpecimensModel = ref(specimensModal)
const localClosedSpecimensModel = ref(specimensModal)
const localMissingSpecimensModel = ref(specimensModal)
const localOffsiteSpecimensModel = ref(specimensModal)
const localTicketsModel = ref({
  sort: {
    orderBy: 'procedureTime',
    direction: 'desc'
  },
  options: []
})

const {
  patient,
  specimensFromPatient,
  specimensClosedFromPatient,
  ticketsFromPatient,
  missingTicketsFromPatient,
  externalClinics
} = useGetters('patientsModule')
const { embryoTypes } = useGetters('specimensModule')
const { allContainersBySite } = useGetters('siteModule')

const { appBaseConfig } = useGetters('authModule')
const { selectedTickets } = useGetters('selectedTicketsModule')

const pageTitle = computed(() => {
  return patient.value?.firstName
    ? `You're viewing ${patient.value.firstName} ${patient.value.lastName}`
    : ''
})
const patientFields = computed<PatientField[]>(() => {
  const {
    address: { street, street2, city, zip, state, country },
    birthDate,
    email,
    firstName,
    identificationNumber,
    lastName,
    phone
  } = patient.value

  const patientName = `${firstName} ${lastName}`
  const birthDay = dayjs(birthDate).format('DD MMM YYYY').toUpperCase()
  const formattedAddress = [street, street2, city, zip, state, country].join(' ').toUpperCase()

  return [
    {
      label: 'First and Last Name',
      value: patientName,
      dataTest: 'patient-name'
    },
    {
      label: 'Patient ID',
      value: identificationNumber,
      dataTest: 'identification-number'
    },
    {
      label: 'Date of Birth',
      value: birthDay,
      dataTest: 'birth-day'
    },
    {
      label: 'Email Address',
      value: email,
      dataTest: 'email'
    },
    {
      label: 'Phone Number',
      value: phone,
      dataTest: 'phone'
    },
    {
      label: 'Address',
      value: formattedAddress,
      addClasses: ['col-span-2'],
      dataTest: 'address'
    }
  ]
})
const activeSpecimensOptions = computed(() => {
  return formatSpecimenTabs(localSpecimens.value, numberOfActiveSpecimens.value)
})
const closedSpecimensOptions = computed(() => {
  return formatSpecimenTabs(localClosedSpecimens.value, numberOfClosedSpecimens.value)
})
const missingSpecimensOptions = computed(() => {
  return formatSpecimenTabs(localMissingSpecimens.value, numberOfMissingSpecimens.value)
})
const offsiteSpecimensOptions = computed(() => {
  return formatSpecimenTabs(localOffsiteSpecimens.value, numberOfOffsiteSpecimens.value)
})
const numberOfActiveSpecimens = computed<NumberOfSpecimens>(() => {
  const embryoCount = composeSpecimensCount(localSpecimens.value, SPECIMEN_TYPE_EMBRYO)
  const oocyteCount = composeSpecimensCount(localSpecimens.value, SPECIMEN_TYPE_EGG)
  return {
    embryoCount,
    oocyteCount,
    totalCount: embryoCount + oocyteCount,
    hasSpecimens: oocyteCount > 0 || embryoCount > 0
  }
})
const numberOfClosedSpecimens = computed<NumberOfSpecimens>(() => {
  const embryoCount = composeSpecimensCount(localClosedSpecimens.value, SPECIMEN_TYPE_EMBRYO)
  const oocyteCount = composeSpecimensCount(localClosedSpecimens.value, SPECIMEN_TYPE_EGG)

  return {
    embryoCount,
    oocyteCount,
    totalCount: embryoCount + oocyteCount
  }
})
const numberOfOffsiteSpecimens = computed<NumberOfSpecimens>(() => {
  const embryoCount = composeSpecimensCount(localOffsiteSpecimens.value, SPECIMEN_TYPE_EMBRYO)
  const oocyteCount = composeSpecimensCount(localOffsiteSpecimens.value, SPECIMEN_TYPE_EGG)

  return {
    embryoCount,
    oocyteCount,
    totalCount: embryoCount + oocyteCount,
    hasSpecimens: !!(oocyteCount || embryoCount)
  }
})
const numberOfMissingSpecimens = computed<NumberOfSpecimens>(() => {
  const embryoCount = composeSpecimensCount(localMissingSpecimens.value, SPECIMEN_TYPE_EMBRYO)
  const oocyteCount = composeSpecimensCount(localMissingSpecimens.value, SPECIMEN_TYPE_EGG)

  return {
    embryoCount,
    oocyteCount,
    totalCount: embryoCount + oocyteCount
  }
})
const localSpecimens = computed(() => {
  const newspecimensFromPatient = filteredSpecimens()
  return newspecimensFromPatient
    .filter((specimen) => !shouldBeOffsiteSpecimen({ specimen }))
    .map(formatSpecimens)
    .map((specimen) =>
      ticketsFromPatient.value.some((el) => el.beaconBarcode === specimen.beaconBarcode)
        ? {
            ...specimen,
            selected: true,
            disabled: true,
            statusImage: {
              sources: [inProgress]
            }
          }
        : specimen
    )
})
const localClosedSpecimens = computed(() => {
  return specimensClosedFromPatient.value
    .map(formatSpecimens)
    .map((specimen) => ({ ...specimen, selected: true, disabled: true }))
})
const localMissingSpecimens = computed(() => {
  return missingSpecimens.value.map(formatSpecimens).map((specimen) =>
    ticketsFromPatient.value.some((el) => el.beaconBarcode === specimen.beaconBarcode)
      ? {
          ...specimen,
          selected: false,
          disabled: false,
          statusImage: {
            sources: [inProgress]
          }
        }
      : specimen
  )
})
const localOffsiteSpecimens = computed(() => {
  const specimens = specimensFromPatient.value.map(formatSpecimens)
  return composeOffsiteSpecimens({ specimens })
})
const localTickets = computed(() => {
  const tickets = ticketsFromPatient.value.map((ticket) => {
    const dateOfBirth = dayjs(ticket.patientDob).valueOf()
    return {
      ...ticket,
      procedureTime: dateAndTime.toLocalTimeZone({ procedureTime: ticket.procedureTime }),
      patientDob: dayjs.utc(Number(dateOfBirth)).format('DD MMM YYYY').toUpperCase()
    }
  })

  return tickets
})
const headers = computed(() => {
  return buildHeaders(activeSpecimensTab.value)
})
const headersClosed = computed(() => {
  return buildHeaders(closedSpecimensTab.value)
})
const headersMissing = computed(() => {
  return buildMissingHeaders(missingSpecimensTab.value)
})
const headersOffSite = computed(() => {
  return buildHeaders(filterOffsite.value)
})
const headersTickets = computed(() => {
  return [
    {
      name: 'Date & Time',
      key: 'procedureTime',
      cssClasses: ['w-64', 'max-w-64']
    },
    {
      name: 'Patient Name',
      key: 'patientName',
      cssClasses: ['w-48', 'max-w-48']
    },
    {
      name: 'Patient ID',
      key: 'identificationNumber',
      cssClasses: ['w-56', 'max-w-56']
    },
    {
      name: 'Date of Birth',
      key: 'patientDob',
      cssClasses: ['w-40', 'max-w-40']
    },
    {
      name: 'Ticket #',
      key: 'ticketId',
      cssClasses: ['w-48', 'max-w-48']
    },
    {
      name: INFECTIOUS_SCREENING_STATUS,
      key: 'screeningStatus',
      cssClasses: ['w-64'],
      component: 'screeningStatus',
      componentOptions: {
        icon: true,
        label: true,
        textColor: 'tmrw-blue-dark',
        iconColor: 'tmrw-blue-dark'
      }
    },
    {
      name: CURRENT_LOCATION,
      key: 'robotLocations',
      component: 'robotLocations',
      cssClasses: ['w-40']
    },
    {
      name: 'Procedure',
      key: 'procedureName',
      filters: [(value) => replaceStringFilter(value)]
    }
  ]
})
const numberTickets = computed(() => {
  return ticketsFromPatient.value.length
})
const subtitleOffsiteSpecimens = computed(() => {
  return composeSubtitleOffsiteSpecimens()
})
const missingSpecimens = computed(() => {
  const newMissingSpecimens: any[] = []
  if (missingTicketsFromPatient.value && missingTicketsFromPatient.value.length > 0) {
    const beaconBarcodesSet = new Set(
      missingTicketsFromPatient.value.map((missingTicket) => missingTicket.beaconBarcode)
    )
    specimensFromPatient.value.forEach((specimen) => {
      if (beaconBarcodesSet.has(specimen.beaconBarcode)) {
        newMissingSpecimens.push(specimen)
      }
    })
  }
  return newMissingSpecimens
})

const { fetchEmbryoTypes } = useActions('specimensModule')
const {
  fetchPatient,
  fetchSpecimensFromPatient,
  fetchClosedSpecimensFromPatient,
  fetchOpenTicketsFromPatient,
  fetchMissingTicketsFromPatient,
  resetState,
  fetchDefaultSpecimens,
  getExternalClinics
} = useActions('patientsModule')

const loadData = async () => {
  try {
    await Promise.all([
      fetchPatient(route.params.patientId),
      getExternalClinics(),
      fetchEmbryoTypes(),
      fetchOpenTicketsFromPatient({
        patientId: route.params.patientId,
        includeChildTickets: true
      }),
      fetchMissingTicketsFromPatient(route.params.patientId),
      fetchSpecimensFromPatient({
        id: route.params.patientId,
        onlyInRobot: false,
        includeClosed: false,
        onlyInClinic: true
      }),
      fetchClosedSpecimensFromPatient({ id: route.params.patientId })
    ])
    loadingPatientData.value = false
    updateActiveSpecimenModel()
  } catch (err) {
    toast.custom('error', 'error', err)
  }
}
const closePatient = () => {
  try {
    fetchDefaultSpecimens()
    let selectedTicketProcedureName
    const process = ss.getFieldSessionStorage('process')

    const { activeSlideSelectedTickets } = process

    switch (selectedTickets.value.length) {
      case 0:
        selectedTicketProcedureName = undefined
        break
      default:
        selectedTicketProcedureName = activeSlideSelectedTickets
          ? selectedTickets.value[activeSlideSelectedTickets - 1].procedureName
          : selectedTickets.value[0].procedureName
        break
    }

    switch (selectedTicketProcedureName) {
      case MISSING_CRYOBEACON:
        return router.push({ path: '/selected-tickets' })
      default:
        return router.push({ path: '/patients-search' })
    }
  } catch (error) {
    throw new Error(`Close Patient -> ${error}`)
  }
}
const handlePatientInventoryRedirect = () => {
  router.push(`/patient-inventory/${patient.value.globalPatientNumber}`)
}
const updatePatient = () => {
  router.push({ path: `/patients/update/${patient.value.globalPatientNumber}` })
}
const handleTabChange = (option) => {
  activeSpecimensTab.value = option.value
}
const handleClosedTabChange = (option) => {
  closedSpecimensTab.value = option.value
}
const handleOffsiteTabChange = (option) => {
  filterOffsite.value = option.value
}
const handleMissingTabChange = (option) => {
  missingSpecimensTab.value = option.value
}
const formatSpecimens = (specimen) => {
  return {
    ...specimen,
    embryoTypeLabel: selectEmbryoTypeLabel(specimen, embryoTypes.value),
    seeMore: {
      icon: 'eye',
      label: 'View',
      to: `/specimen-detail/${Number(route.params.patientId)}/${specimen.specimenId}`
    },
    sourcesValue: {
      icon: 'exclamation-circle',
      label: '',
      to: '',
      iconColor: 'text-red-500'
    }
  }
}
const formatSpecimenTabs = (specimen, specimenCount) => {
  const embryoCryodevices = specimen.filter((cryodevice) => cryodevice.embryo)
  const oocyteCryodevices = specimen.filter((cryodevice) => cryodevice.oocyte)
  const { embryoCount, oocyteCount } = specimenCount
  return [
    {
      embryoDevicesCount: embryoCryodevices.length,
      value: SPECIMEN_TYPE_EMBRYO,
      label: `${embryoCount} Embryos`,
      count: `${embryoCount} Total on ${embryoCryodevices.length} ${CRYODEVICES}`,
      disabled: !embryoCount
    },
    {
      oocyteDevicesCount: oocyteCryodevices.length,
      value: SPECIMEN_TYPE_EGG,
      label: `${oocyteCount} Oocytes`,
      count: `${oocyteCount} Total on ${oocyteCryodevices.length} ${CRYODEVICES}`,
      disabled: !oocyteCount
    }
  ]
}
const composeSpecimensCount = (specimens, specimenType) => {
  const specimenCount =
    specimens?.reduce((accumulator, currentSpecimen) => {
      if (currentSpecimen.specimenType === specimenType) {
        return accumulator + Number(currentSpecimen.specimenCount)
      }
      return accumulator
    }, 0) ?? 0
  return specimenCount
}
const buildHeaders = (currentFilter) => {
  const formattedFilter = currentFilter.toLowerCase()
  const initialHeaders = patientDetailTableHeaders[formattedFilter]
  const linkHeader = {
    name: '',
    key: 'seeMore',
    component: 'link',
    alignment: 'left',
    forceEnabled: true
  }

  const updatedHeaders = [...initialHeaders]

  if (
    localSpecimens.value.filter((specimen) => specimen.sources?.length) ||
    localMissingSpecimens.value.filter((specimen) => specimen.sources?.length)
  ) {
    const importHeader = patientDetailTableHeaders.sourcesValueHeader
    updatedHeaders.push(importHeader)
  }

  updatedHeaders.push(linkHeader)
  return updatedHeaders
}
const buildMissingHeaders = (currentFilter) => {
  const formattedFilter = currentFilter.toLowerCase()
  const initialHeaders = patientDetailTableHeaders[formattedFilter]
  const linkHeader = {
    name: '',
    key: 'seeMore',
    component: 'link',
    alignment: 'left',
    forceEnabled: true
  }

  const alertHeader = {
    name: '',
    key: 'sourcesValue',
    alignment: 'left',
    component: 'icon'
  }

  const updatedHeaders = [...initialHeaders]

  if (
    localSpecimens.value.filter((specimen) => specimen.sources).length ||
    localMissingSpecimens.value.filter((specimen) => specimen.sources).length
  ) {
    const importHeader = patientDetailTableHeaders.sourcesValueHeader
    updatedHeaders.push(importHeader)
  }

  updatedHeaders.push(alertHeader, linkHeader)

  return updatedHeaders
}
const getActiveTab = (specimensCount) => {
  const { embryoCount } = specimensCount
  return embryoCount ? SPECIMEN_TYPE_EMBRYO : SPECIMEN_TYPE_EGG
}
const updateActiveSpecimenModel = () => {
  const activeTab = getActiveTab(numberOfActiveSpecimens.value)
  activeSpecimensTab.value = activeTab
  localSpecimensModel.value = updateSpecimenModal({
    options: localSpecimens.value,
    optionsFilter: activeTab,
    sort: localSpecimensModel.value.sort,
    shouldCheckSpecimenImport: true
  })
}
const updateClosedSpecimensModel = () => {
  const activeTab = getActiveTab(numberOfClosedSpecimens.value)
  closedSpecimensTab.value = activeTab
  localClosedSpecimensModel.value = updateSpecimenModal({
    options: localClosedSpecimens.value,
    optionsFilter: activeTab,
    sort: localClosedSpecimensModel.value.sort,
    shouldCheckSpecimenImport: true
  })
}
const updateOffsiteSpecimensModel = () => {
  localOffsiteSpecimensModel.value = updateSpecimenModal({
    options: localOffsiteSpecimens.value,
    optionsFilter: SPECIMEN_TYPE_EMBRYO,
    sort: localOffsiteSpecimensModel.value.sort,
    shouldCheckSpecimenImport: true
  })
}
const updateMissingSpecimensModel = () => {
  const activeTab = getActiveTab(numberOfMissingSpecimens.value)
  missingSpecimensTab.value = activeTab
  localMissingSpecimensModel.value = updateSpecimenModal({
    options: localMissingSpecimens.value,
    optionsFilter: activeTab,
    sort: localMissingSpecimensModel.value.sort,
    shouldCheckSpecimenImport: true
  })
}
const updateActiveSpecimenTab = () => {
  localSpecimensModel.value = updateSpecimenModal({
    options: localSpecimens.value,
    optionsFilter: activeSpecimensTab.value,
    sort: localSpecimensModel.value.sort,
    shouldCheckSpecimenImport: true
  })
}
const updateClosedSpecimenTab = () => {
  localClosedSpecimensModel.value = updateSpecimenModal({
    options: localClosedSpecimens.value,
    optionsFilter: closedSpecimensTab.value,
    sort: localClosedSpecimensModel.value.sort
  })
}
const updateOffsiteSpecimenTab = () => {
  localOffsiteSpecimensModel.value = updateSpecimenModal({
    options: localOffsiteSpecimens.value,
    optionsFilter: filterOffsite.value,
    sort: localOffsiteSpecimensModel.value.sort
  })
}
const updateMissingSpecimenTab = () => {
  localMissingSpecimensModel.value = updateSpecimenModal({
    options: localMissingSpecimens.value,
    optionsFilter: missingSpecimensTab.value,
    sort: localMissingSpecimensModel.value.sort
  })
}
const updateLocalTickets = () => {
  const { sort } = localTicketsModel.value
  const options = localTickets.value
  localTicketsModel.value = { sort, options }
}
const getSpecimenImport = (specimen, index) => {
  return checkSpecimenImport({
    externalClinics: externalClinics.value,
    hasLocalSpecimen: Boolean(localSpecimens.value[index]),
    specimen
  })
}
const updateSpecimenModal = ({
  options,
  optionsFilter,
  sort,
  shouldCheckSpecimenImport = false
}) => {
  const filteredOptions = options.filter((specimen) => specimen.specimenType === optionsFilter)
  const updatedOptions = shouldCheckSpecimenImport
    ? filteredOptions.map((specimen, index) => getSpecimenImport(specimen, index))
    : filteredOptions

  return {
    sort,
    options: updatedOptions
  }
}
const filteredSpecimens = () => {
  // removing from localSpecimens the missing specimens as those got their own category
  const { siteId, unitIds } = appBaseConfig.value
  const temporaryUnits = allContainersBySite.value?.filter(
    (container) => container.robotNumber === TEMPORARY && container.siteId === siteId
  )
  let combinedUnitIds = unitIds
  for (const tempUnit in temporaryUnits) {
    if (!Object.values(combinedUnitIds).includes(temporaryUnits[tempUnit].containerId)) {
      combinedUnitIds.push(temporaryUnits[tempUnit].containerId)
    }
  }

  if (missingTicketsFromPatient.value && missingTicketsFromPatient.value.length > 0) {
    const localSpecimens = missingTicketsFromPatient.value.map((missingTicket) =>
      specimensFromPatient.value.filter((specimen) => {
        return missingTicket.beaconBarcode !== specimen.beaconBarcode
      })
    )
    const [newSpecimensFromPatient] = localSpecimens
    return newSpecimensFromPatient
  }
  return specimensFromPatient.value
}
const composeSubtitleOffsiteSpecimens = () => {
  const isSingular = numberOfOffsiteSpecimens.value.totalCount === 1
  return `${numberOfOffsiteSpecimens.value.totalCount} ${
    isSingular ? SPECIMEN_CAPITALIZED : SPECIMENS_CAPITALIZED
  } on Off-site Inventory`
}
const composeOffsiteSpecimens = ({ specimens }) => {
  const { siteId, unitIds } = appBaseConfig.value

  if (!specimens || !unitIds || !siteId) return null

  const unitIdsMap = new Set(unitIds)
  
  const offSiteSpecimens = specimens.filter(
    (specimen) => !unitIdsMap.has(specimen.unitId) || specimen.siteId !== siteId
  )
  return offSiteSpecimens
}
const shouldBeOffsiteSpecimen = ({ specimen }) => {
  if (!specimen || !localOffsiteSpecimens.value) return null
  return localOffsiteSpecimens.value
    .map((localSpecimen) => localSpecimen.specimenId)
    .includes(specimen.specimenId)
}

onMounted(async () => {
  await loadData()
})

onBeforeUnmount(() => {
  resetState()
})
watch(localSpecimens, (newLocalSpecimensValue) => {
  if (newLocalSpecimensValue) {
    updateActiveSpecimenModel()
  }
})
watch(localClosedSpecimens, (newLocalClosedSpecimensValue) => {
  if (newLocalClosedSpecimensValue) {
    updateClosedSpecimensModel()
  }
})
watch(localOffsiteSpecimens, (nextLocalOffsiteSpecimens) => {
  if (nextLocalOffsiteSpecimens) {
    updateOffsiteSpecimensModel()
  }
})
watch(localMissingSpecimens, (newLocalMissingSpecimensValue) => {
  if (newLocalMissingSpecimensValue) {
    updateMissingSpecimensModel()
  }
})
watch(localTickets, (newLocalTicketsValue) => {
  if (newLocalTicketsValue) {
    updateLocalTickets()
  }
})
watch(activeSpecimensTab, (newFilterValue) => {
  if (newFilterValue) {
    updateActiveSpecimenTab()
  }
})
watch(closedSpecimensTab, (newFilterClosedValue) => {
  if (newFilterClosedValue) {
    updateClosedSpecimenTab()
  }
})
watch(filterOffsite, (nextFilterOffsite) => {
  if (nextFilterOffsite) {
    updateOffsiteSpecimenTab()
  }
})
watch(missingSpecimensTab, (newFilterClosedValue) => {
  if (newFilterClosedValue) {
    updateMissingSpecimenTab()
  }
})
/*
export default {
  watch: {
    
  },
  components: {
    ActionBar,
    ButtonDynamic,
    DynamicTitle,
    LoadingUi,
    PatientDocuments,
    ScrollTableComponent,
    Tabs,
    TopHeader
  }
}
*/
</script>
