<template>
  <MainContainer data-test="biorepository-flight-board" gridSlots="4">
    <loading-ui v-if="isLoading" modal message="Creating CryoShipper Ticket" />
    <top-header>{{ headerTitle }}</top-header>
    <ActionBar data-test="customize-beacons__action-bar" colsDistribution="6/6" setLeftSideToBottom>
      <template v-slot:left-side>
        <message-card class="float-left">
          <span>Confirm CryoShipper</span>
        </message-card>
      </template>
      <template v-slot:right-side>
        <button-dynamic
          btnType="button"
          btnText="Cancel"
          btnStyle="secondary"
          addMarginLeft
          @click="handleCancel"
        />
        <button-dynamic
          btn-data-test="new-cryoshipper-button"
          btnType="button"
          btnText="Create CryoShipper Ticket"
          btnStyle="primary"
          addMarginLeft
          show-icon
          font-awesome-icon-class="plus-circle"
          @click="handleCreateCryoShipper"
        />
      </template>
    </ActionBar>
    <bread-crumbs size="base" :items="breadCrumsItems" />
    <div class="responsive-container">
      <div class="instructions">
        <p class="text-3.5xl leading-10">
          TMRW will coordinate the CryoShipper ticket within three business days.
        </p>
        <div class="flex text-2xl mt-10">
          <div class="flex-1 mr-16">
            For more information on this CryoShipper<br />ticket, contact the TMRW Biorepository:
            <br /><br />
            NY Location:
            <a href="mailto:bio.ny.cryo@tmrw.org" class="underline">bio.ny.cryo@tmrw.org</a><br />
            CO Location:
            <a href="mailto:bio.co.cryo@tmrw.org" class="underline">bio.co.cryo@tmrw.org</a>
          </div>
          <div class="flex-1">
            <p>
              For technical support, contact the<br />TMRW Operations Center: <br /><br />
              <a href="mailto:support@tmrw.org" class="underline">support@tmrw.org</a><br />
              US <a href="tel:+18334338679">+1 833-433-8679</a><br />
              UK <a href="tel:+448081694118">+44 808-169-4118</a>
            </p>
          </div>
        </div>
      </div>
      <div class="cryoshipper" :class="cryoShipperClass">
        <div class="grids">
          <div
            v-for="container in containers"
            class="grid-container"
            :key="container.key"
            :class="[container.display]"
          >
            <div class="flex justify-center items-center">
              <img :src="container.screeningStatus" width="20" />
              <span class="text-2xl font-bold ml-3">{{ container.count }}</span>
            </div>
            <span
              class="text-xs font-inconsolata mt-3 inline-block"
              v-html="container.label"
            ></span>
          </div>
        </div>
        <p
          class="text-center text-tmrw-blue-dark font-bold p-4 text-xl"
          v-html="cryoShipperLabel"
        />
      </div>
    </div>
  </MainContainer>
</template>

<script setup lang="ts">
import useGetters from '@/composables/useGetters'
import {
  ROUTE_MOVE_TICKET,
  STEP_BIOREPOSITORY_FLIGHTBOARD
} from '@/constants/moveLocationTicketSteps'
import { BiorepositoryTicketModule } from '@/store/modules/biorepositoryTicket'
import { computed, ref } from 'vue'
import { useRouter } from 'vue-router'

import ActionBar from '@/components/ActionBar/ActionBar.vue'
import ButtonDynamic from '@/components/ButtonDynamic/ButtonDynamic.vue'
import MainContainer from '@/components/MainContainer/MainContainer.vue'
import TopHeader from '@/components/TopHeader/TopHeader.vue'
import BreadCrumbs from '@/components/BreadCrumbs/BreadCrumbs.vue'
import MessageCard from '@/components/MessageCard/MessageCard.vue'
import LoadingUi from '@/components/LoadingUi/LoadingUi.vue'

import screenStatusUnscreened from '@/assets/images/screenstatus-unscreened-white.svg'
import screenStatusPositive from '@/assets/images/screenstatus-positive-white.svg'
import screenStatusNegative from '@/assets/images/screenstatus-negative-white.svg'
import useActions from '@/composables/useActions'

import toast from '@/config/toast'
import { getBiorepoBreadcrumbs } from '@/helpers/biorepository'
import {
  CRYOBEACON,
  CRYOSHIPPER_MEDIUM,
  CRYOSHIPPER_SMALL,
  MEDIUM_CRYOSHIPPER_FULL_SIZE,
  MEDIUM_CRYOSHIPPER_GRID_SIZE,
  PROCEDURE_TYPE_CRYOSHIPPER,
  SMALL_CRYOSHIPPER_SIZE,
  TICKET_CRYOSHIPPER
} from '@/constants'
import { getSiteProperty, TICKET_CRYOSHIPPER_SIZE_LIMIT } from '@/helpers/featureFlags'

const router = useRouter()

type Grid = {
  key: 'top' | 'bottom'
  count: number
  display: 'flex' | 'hidden'
  label: string
  screeningStatus: string
}

const smallCryoShipperSize = ref(
  getSiteProperty(TICKET_CRYOSHIPPER_SIZE_LIMIT, SMALL_CRYOSHIPPER_SIZE)
)

const breadCrumsItems = computed(() => {
  return getBiorepoBreadcrumbs({ active: 'confirm', ticketMode: TICKET_CRYOSHIPPER })
})

const isLoading = ref(false)

const { sourceLocation, destinationLocation, cryoShipperTickets } =
  useGetters<BiorepositoryTicketModule['getters']>('biorepoTicketModule')

const headerTitle = computed(
  () =>
    `Create a New CryoShipper Ticket from ${sourceLocation.value?.name} to ${destinationLocation.value?.name}`
)

const ticketsByStatus = computed(() => {
  const tickets = {}
  cryoShipperTickets.value?.forEach((ticket) => {
    if (!tickets[ticket.screeningStatus]) {
      tickets[ticket.screeningStatus] = []
    }
    tickets[ticket.screeningStatus].push(ticket)
  })

  return tickets
})

const shipmentType = computed(() => {
  const keys = Object.keys(ticketsByStatus.value)
  // must be one screening status and less than 5 to be a small cryoshipper
  return keys.length === 1 && ticketsByStatus.value[keys[0]].length <= smallCryoShipperSize.value
    ? CRYOSHIPPER_SMALL
    : CRYOSHIPPER_MEDIUM
})

const cryoShipperClass = computed(() => {
  return shipmentType.value === CRYOSHIPPER_SMALL ? 'cryoshipper-small' : 'cryoshipper-medium'
})

const cryoShipperLabel = computed(() => {
  const [size, limit] =
    shipmentType.value === CRYOSHIPPER_SMALL
      ? ['Small', smallCryoShipperSize.value]
      : ['Medium', MEDIUM_CRYOSHIPPER_FULL_SIZE]
  return `${size} CryoShipper<br>${limit} ${CRYOBEACON} Capacity`
})

const containers = computed(() => {
  const STATUS_ICONS: string[] = [
    '',
    screenStatusPositive,
    screenStatusNegative,
    screenStatusUnscreened
  ]
  const keys = Object.keys(ticketsByStatus.value)
  const grids: Grid[] = [
    {
      key: 'top',
      count: 0,
      display: 'flex',
      label: 'top',
      screeningStatus: ''
    },
    {
      key: 'bottom',
      count: MEDIUM_CRYOSHIPPER_GRID_SIZE,
      display: 'flex',
      label: 'bottom',
      screeningStatus: ''
    }
  ]

  if (keys.length === 1) {
    // one screening status
    grids[0].screeningStatus = STATUS_ICONS[Number(keys[0])]
    grids[1].screeningStatus = STATUS_ICONS[Number(keys[0])]

    if (ticketsByStatus.value[keys[0]].length <= MEDIUM_CRYOSHIPPER_GRID_SIZE) {
      // less than 49, hiding top
      grids[0].display = 'hidden'
      grids[1].count = ticketsByStatus.value[keys[0]].length
      grids[1].label = '&nbsp;'
    } else {
      const countTop = ticketsByStatus.value[keys[0]].length - MEDIUM_CRYOSHIPPER_GRID_SIZE
      grids[0].display = 'flex'
      grids[0].count = countTop
      grids[0].label = `Top${countTop === MEDIUM_CRYOSHIPPER_GRID_SIZE ? ' (Full)' : ''}`

      grids[1].display = 'flex'
      grids[1].count = MEDIUM_CRYOSHIPPER_GRID_SIZE
      grids[1].label = 'Bottom (Full)'
    }
  } else {
    const topIndex =
      ticketsByStatus.value[keys[0]].length > ticketsByStatus.value[keys[1]].length ? 1 : 0
    const bottomIndex = 1 - topIndex
    grids[0].count = ticketsByStatus.value[keys[topIndex]].length
    grids[0].display = 'flex'
    grids[0].label = `Top${grids[0].count === MEDIUM_CRYOSHIPPER_GRID_SIZE ? ' (Full)' : ''}`
    grids[0].screeningStatus = STATUS_ICONS[Number(keys[topIndex])]

    grids[1].count = ticketsByStatus.value[keys[bottomIndex]].length
    grids[1].display = 'flex'
    grids[1].label = `Bottom${grids[1].count === MEDIUM_CRYOSHIPPER_GRID_SIZE ? ' (Full)' : ''}`
    grids[1].screeningStatus = STATUS_ICONS[Number(keys[bottomIndex])]
  }
  return grids
})

const { createCryoShipperTicket } =
  useActions<BiorepositoryTicketModule['actions']>('biorepoTicketModule')

const { pushSelectedTickets, pushSelectedViewMode, pushViewTicket } =
  useActions('selectedTicketsModule')

const handleCancel = () => {
  router.push({ name: STEP_BIOREPOSITORY_FLIGHTBOARD })
}

const handleCreateCryoShipper = async () => {
  const children: number[] = cryoShipperTickets.value.map(({ ticketId }) => ticketId)
  try {
    isLoading.value = true
    const newTicket = await createCryoShipperTicket({
      sourceSiteId: sourceLocation.value?.id!,
      destinationSiteId: destinationLocation.value?.id!,
      children
    })

    isLoading.value = false
    toast.success('CryoShipper ticket created successfully!')
    pushSelectedTickets(newTicket)
    pushSelectedViewMode('cryoshipper-created')
    const ticketData = {
      ticketType: PROCEDURE_TYPE_CRYOSHIPPER,
      ticketId: newTicket[0].ticketId
    }
    pushViewTicket(ticketData)
    router.push({ name: ROUTE_MOVE_TICKET })
  } catch (err: any) {
    isLoading.value = false
    toast.error({ title: err.message })
  }
}
</script>

<style lang="scss" scoped>
.responsive-container {
  @apply flex flex-col-reverse bg-white p-16 rounded-lg;
}
.instructions {
  @apply flex-1 text-tmrw-blue font-semibold;
}

.cryoshipper {
  @apply bg-tmrw-green-light rounded-lg bg-no-repeat bg-[center_top_1rem] mb-16;
  width: 100%;
}
.grids {
  @apply flex flex-col justify-center items-center;
}
.cryoshipper-medium {
  background-image: url('../../../assets/images/cryoshipper-medium.svg');
  .grids {
    width: 192px;
    height: 297px;
    margin: 15px auto 0;
    .grid-container {
      width: 100px;
      height: 100px;
      @apply bg-tmrw-blue rounded-lg flex-col justify-end m-2 text-white text-center p-2;
    }
  }
}
.cryoshipper-small {
  background-image: url('../../../assets/images/cryoshipper-small.svg');
  background-size: 141.4px 310.4px;
  .grids {
    width: 142px;
    height: 311px;
    margin: 25px auto 0;
    .grid-container {
      width: 100px;
      height: 156px;
      @apply bg-tmrw-blue rounded-lg flex-col justify-center m-2 text-white text-center px-2 pt-4;
    }
  }
}
@media (min-width: 1450px) {
  .responsive-container {
    @apply h-[33.5rem] flex-row;
  }
  .cryoshipper {
    @apply mx-16 mb-0;
    width: 305px;
    height: 414px;
  }
}

.color-change {
  @apply bg-gradient-to-r to-white from-[49.9%] from-tmrw-green to-[50.1%] inline-block;
  background-size: 220% auto;
  background-position: 100% 0;
  animation: colorChange 0.5s forwards;
  animation-delay: 1s;
}
@keyframes colorChange {
  0% {
    background-position: 100% 0;
  }
  100% {
    background-position: 0 0;
  }
}
</style>
