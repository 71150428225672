<template>
  <MainContainer data-test="final-ticket-view" gridSlots="3">
    <top-header @backStep="handleClickBack"
      >Select the items to create a {{ subType }} Batch Ticket</top-header
    >
    <ActionBar data-test="final-ticket-view__action-bar" colsDistribution="6/6">
      <template v-slot:left-side>&nbsp;</template>
      <template v-slot:right-side>
        <span
          v-if="selectedItems.length > 0"
          class="font-inconsolata text-2xl text-tmrw-green-light mr-6"
          data-test="selected-tickets"
          >{{ selectedItems.length }} Tickets Selected</span
        >
        <span
          v-if="selectedItems.length > 0 && subType === batchRetrieve"
          class="font-inconsolata text-2xl text-tmrw-green-light mr-6"
          data-test="selected-robot"
        >
          | Location: {{ robotName }}
        </span>
        <button-dynamic
          btnType="button"
          btnText="Next"
          btnStyle="primary"
          :hasMarginRight="true"
          :hasMarginBottom="true"
          :showIcon="true"
          fontAwesomeIconClass="check-circle"
          :isDisabled="selectedItems.length <= 1 || selectedItems.length > maxBatchLength"
          @click="constructTicketViewData"
        />
      </template>
    </ActionBar>

    <TableComponent
      v-if="!processingUpload && shouldHaveTickets(apiElements)"
      :headers="Headers"
      :procedure="subType"
      :apiElements="apiElements"
      :referenceKey="referenceKey"
      :screenigKey="screenigKey"
      :disabledKey="disabledKey"
      :procedureKey="procedureKey"
      :robotIdKey="robotIdKey"
      :robotLayoutTypeIdKey="robotLayoutTypeIdKey"
      @sortColumn="sortColumn"
      @selectedColumn="selectedColumn"
      :sort="sort"
    />

    <div v-else class="bg-white rounded-lg rounded-tl-none h-40 flex items-center p-6">
      <span
        class="ticket-table__header bg-gradient-to-r from-tmrw-green-light to-transparent flex justify-between p-5 text-tmrw-blue-light rounded-lg"
      >
        There's no ticket available for batching
      </span>
    </div>
  </MainContainer>
</template>
<script setup lang="ts">
import TopHeader from '@/components/TopHeader/TopHeader.vue'
import MainContainer from '@/components/MainContainer/MainContainer.vue'
import ActionBar from '@/components/ActionBar/ActionBar.vue'
import TableComponent from '@/components/TableComponent/TableComponent.vue'
import ButtonDynamic from '@/components/ButtonDynamic/ButtonDynamic.vue'
import * as $ss from '@/config/session-storage-help'
import { executeNextTicketStep, executePreviousTicketStep } from '@/helpers/manageTicket'
import { setBatchData, resetTicketProcess } from '@/helpers/manageTicket/utils'
import {
  FILTER_FROM_TODAY,
  PROCEDURE_BATCH_RETRIEVE,
  INFECTIOUS_SCREENING_STATUS,
  PROCEDURE_TYPE_BATCH_UPPERCASE,
  CRYOBEACONS,
  PROCEDURE_TYPE_BEACON_MOVE,
  LOADING_BATCHABLE_TICKETS,
  SOURCE,
  DESTINATION,
  COMPLETE,
  ROBOT_ID,
  CURRENT_LOCATION,
  MAX_BATCH_LENGTH
} from '@/constants'
import {
  SortFactory,
  ApiFactory,
  ApiCallsFactory,
  DateFactory,
  ObjectFactory,
  ScreeningStatusFactory,
  SelectionFactory,
  RequestParams,
  PersistenceFactory,
  StatusFactory,
  SortProps
} from '@/factories'
import toast from '@/config/toast'
import { TicketsResponse, THeaders } from './Interfaces'
import { getRobotName, RobotLocation } from '@/helpers/cryoBeaconLocation'
import { inject, onMounted, ref, watch } from 'vue'
import useActions from '@/composables/useActions'
import { useRouter } from 'vue-router'
import useGetters from '@/composables/useGetters'

type composeDataType = {
  robotLocations: {
    id: string
    value: [
      {
        robotNumber: number
        type: string
        robotId: string
        robotLayoutTypeId: number
      }
    ]
  }
  patientDob: {
    id: string
    value: string
  }
  procedureTime: {
    id: string
    value: string
  }
  procedureName: {
    id: string
    value: string
  }
  procedureType: {
    id: string
    value: string
  }
  screeningStatus: {
    id: string
    value: number
  }
  state: {
    id: string
    value: string
  }
  ticketId: {
    id: string
    value: number
  }
  identificationNumber: {
    id: string
    value: number
  }
  patientName: {
    id: string
    value: string
  }
  source: {
    id: string
    value: number
  }
  procedure: {
    id: string
    value: string
  }
}

type batchPersistenceType = {
  batchPersistence: (
    apiElements: object[],
    selectedItems: {
      map: (params: {}) => {}
      splice: (index: number, itemsToRemove: number) => []
      push: (params: {}) => []
      length: number
    },
    robotSelected: number | object[],
    referenceKey: number,
    procedureName: string,
    disabledKey: number,
    screenigKey: number,
    procedureKey: number,
    robotIdKey: number,
    robotLayoutTypeIdKey: number
  ) => object
}

const fromRoute = inject<{
  name: string
}>('fromRoute')

const router = useRouter()

const filter = ref<string>(FILTER_FROM_TODAY)
const sort = ref<SortFactory>()
const subType = ref<string>($ss.getFieldSessionStorage('newTicket', 'subType'))
const data = ref<ReturnType<typeof Object>>([])
const Headers = ref<THeaders>([
  'Date and Time',
  'Patient Name',
  'ID Number',
  'Date of Birth',
  'Ticket #',
  INFECTIOUS_SCREENING_STATUS,
  CURRENT_LOCATION,
  'Procedure',
  'Status'
])
const apiElements = ref<object[]>([])
const composedData = ref<ReturnType<typeof Object>>([])
const referenceKey = ref<number>(4) // index of the ticketId located on apiElements Array as flag
const disabledKey = ref<number>(6) // index of the RobotLocation located on apiElements Array as flag
const screenigKey = ref<number>(5) // index of screening status located on apiElements Array as flag,
const procedureKey = ref<number>(9) // index of procedure name located on apiElements Array as flag
const robotIdKey = ref<number>(10) // index of robotIdKey located on apiElements Array as flag
const robotLayoutTypeIdKey = ref<number>(11) // index of robotLayoutTypeId located on apiElements Array as flag
const selectedItems = ref<ReturnType<typeof Object>>([])
const robotSelected = ref<object[] | number>([])
const robotName = ref('')
const appBaseConfig = ref<ReturnType<typeof Object>>({
  siteId: $ss.getFieldSessionStorage('config', 'siteId') || null,
  clinicId: $ss.getFieldSessionStorage('config', 'clinicId') || null,
  cpId: $ss.getFieldSessionStorage('config', 'collectionProtocolId') || null,
  token: $ss.getFieldSessionStorage('user', 'token') || null,
  userId: $ss.getFieldSessionStorage('user', 'userId') || null
})
const beacons = ref<object[]>([])
const apiCacheResponse = ref<object[]>([])
const batchRetrieve = ref<string>(PROCEDURE_BATCH_RETRIEVE)
const processingUpload = ref<boolean>(true)
const fromRouteName = ref()
const maxBatchLength = ref(MAX_BATCH_LENGTH)

const { currentTicketStep, selectedMethod } = useGetters('newTicketModule')
const { allContainersBySite } = useGetters('siteModule')

const { displaySpinner, hideSpinner } = useActions('spinnerModule')
const constructURL = (): string => {
  // define procedure type
  const procedureSubType = subType.value === 'Store' ? 'IN' : 'OUT'
  // get parameters by filter value
  const params = new RequestParams(filter.value)
  const response = params.getTicketsRequestParams()
  const isBiorepositoryRoute = $ss.getFieldSessionStorage('process', 'isBiorepositoryRoute')
  const moveLocationFilter = isBiorepositoryRoute
    ? `&procedure=${PROCEDURE_TYPE_BEACON_MOVE}&includeAllSites=false&skipExclusions=true`
    : ''
  // return url to execuute api call
  return `/tickets?dateFromMsUtc=${response.dateFromMsUtc}&cpId=${appBaseConfig.value.cpId}&batcheable=true&robotTransfer=${procedureSubType}${moveLocationFilter}`
}
const selectedColumn = (
  id: number,
  screeningId: number,
  robotLocation: number,
  procedure: string,
  itemRef: any,
  robotId: string,
  robotLayoutTypeId: number
): void => {
  robotSelected.value = robotLocation
  robotName.value = getRobotName({ robotId, robotLayoutTypeId } as RobotLocation) // Update to include robotLayoutTypeId
  // @ts-ignore
  const isSelected: string =
    itemRef.currentTarget.classList[itemRef.currentTarget.classList.length - 1]
  const selectColumn = new SelectionFactory(
    isSelected,
    selectedItems.value,
    id,
    procedure,
    robotId,
    screeningId,
    robotLayoutTypeId
  )
  // clone array to update the watcher
  selectedItems.value = [...selectColumn.selectedColumn()]
  // sorting response
  sortColumn()
  // construct view
}
const sortColumn = (sortProps: SortProps = {}): void => {
  // cleaning
  composedData.value = []
  // executing sorting by key

  sort.value!.sortColumn(sortProps)
  // composing object response
  const api = new ApiFactory(data.value)
  const response = api.compositeResponse()
  // assign it compose it response to local reference
  composedData.value = response
  // creating local view
  apiElementsFactory()
  selectedPersistence()
}
const getLocationFromItem = (item: composeDataType) => {
  if (item.procedure.value !== PROCEDURE_TYPE_BEACON_MOVE) {
    return item.robotLocations.value[0]
  }
  const robotLocationType = item.state.value === COMPLETE ? DESTINATION : SOURCE
  return item.robotLocations.value.find((robotLocation) => robotLocation.type === robotLocationType)
}

const validateItem = (
  itemToValidate: composeDataType
): { valid: boolean; item: composeDataType } => {
  const keys = ['patientName', 'identificationNumber', 'patientDob']
  const validation = {
    item: {
      ...itemToValidate
    },
    valid: true
  }
  keys.forEach((id) => {
    if (!itemToValidate[id]) {
      validation.item[id] = {
        id,
        value: ''
      }
      validation.valid = false
    }
  })

  return validation
}
const apiElementsFactory = (validate: boolean = false): void => {
  apiElements.value = []
  const invalidTickets: number[] = []
  composedData.value.forEach((rawItem: composeDataType) => {
    const { item, valid } = validateItem(rawItem)
    if (!valid) {
      invalidTickets.push(item.ticketId.value)
    }

    const location = getLocationFromItem(item) || item.robotLocations.value[0]
    // convert unix time to readable time
    const newProceduteTime: { procedureTime: () => string } = new DateFactory(
      item.procedureTime.value
    )
    const newPatientDateOfBirth: { patientDob: () => string } = new DateFactory(
      item.patientDob.value
    )
    const ProcedureTime: { composite: () => object } = new ObjectFactory(
      item.procedureTime,
      'value',
      newProceduteTime.procedureTime()
    )
    const PatientDOB: { composite: () => object } = new ObjectFactory(
      item.patientDob,
      'value',
      newPatientDateOfBirth.patientDob()
    )
    ProcedureTime.composite()
    PatientDOB.composite()
    const currentLocation = allContainersBySite.value.filter(
      (container: { containerId: number }) => container.containerId === Number(location.robotId)
    )[0]?.robotName
    // adding text to robotLocation
    const RobotName: { deepComposite: () => object } = new ObjectFactory(
      item.robotLocations,
      'robotNumber',
      currentLocation,
      location.robotNumber
    )
    RobotName.deepComposite()
    const RobotId: { deepComposite: () => object } = new ObjectFactory(
      item.robotLocations,
      ROBOT_ID,
      location.robotId
    )
    RobotId.deepComposite()
    const RobotLayoutTypeId: { deepComposite: () => object } = new ObjectFactory(
      item.robotLocations,
      'robotLayoutTypeId',
      location.robotLayoutTypeId
    )
    RobotLayoutTypeId.deepComposite()
    // getting screening status
    if (!item.screeningStatus) {
      // eslint-disable-next-line no-param-reassign
      item.screeningStatus = { id: 'screeningStatus', value: 0 } as any
    }

    const newScreeningStatus: ScreeningStatusFactory = new ScreeningStatusFactory(
      item.screeningStatus?.value
    )
    const composingScreeningStatus: { composite: () => object } = new ObjectFactory(
      item.screeningStatus,
      'value',
      newScreeningStatus.compositeResponse(),
      item.screeningStatus?.value || 0
    )
    composingScreeningStatus.composite()
    // getting status
    const getTicketStatus: { batchStatus: () => string } = new StatusFactory(
      item.state.value,
      item.procedureName.value,
      item.source.value,
      item.procedureType.value
    )
    const TicketStatusCompose: { composite: () => object } = new ObjectFactory(
      item.state,
      'value',
      getTicketStatus.batchStatus(),
      item.state.value
    )
    TicketStatusCompose.composite()

    // composing the data to be displayed
    const tempArray = [
      item.procedureTime,
      item.patientName,
      item.identificationNumber,
      item.patientDob,
      item.ticketId,
      item.screeningStatus,
      location.robotNumber,
      item.procedureName,
      item.state,
      item.procedure,
      location.robotId,
      location.robotLayoutTypeId
    ] as object[]

    apiElements.value.push(tempArray)
  })
  if (validate && invalidTickets.length) {
    const ticketS = invalidTickets.length > 1 ? 'Tickets' : 'Ticket'
    const haveHas = invalidTickets.length > 1 ? 'have' : 'has'
    toast.warn(
      `${ticketS} ${invalidTickets.join(',')} ${haveHas} missing or invalid patient information`
    )
  }
}
const selectedPersistence = (): void => {
  // implementing persistence logic
  const newPersistence: batchPersistenceType | ReturnType<typeof Object> = new PersistenceFactory()
  newPersistence.batchPersistence(
    apiElements.value,
    selectedItems.value,
    robotSelected.value,
    referenceKey.value,
    subType.value,
    disabledKey.value,
    screenigKey.value,
    procedureKey.value,
    robotIdKey.value,
    robotLayoutTypeIdKey.value
  )
}
const constructTicketViewData = async () => {
  try {
    displaySpinner('Processing Batch Tickets')
    // cleaning storage
    resetTicketProcess()
    // Set array to push Patients names for all batched tickets
    const batchPatient: {
      birthDate: string
      clinicId: number
      firstName: string
      fullName?: string
      globalPatientNumber: number
      identificationNumber: string
      lastName: string
      selected?: boolean
      siteId: number
      specimenCount: number
    }[] = []
    const batchSharedPatientIds: number[][] = []
    // getting the data for any particular ticket
    return selectedItems.value.map(async (selectedItem: TicketsResponse) => {
      // constructing the tickets url
      const url = `/tickets/${selectedItem.ticketId}?cpId=${appBaseConfig.value.cpId}`
      // creating a new instance of the promise call
      const apiCall = new ApiCallsFactory(url, appBaseConfig.value.token)
      const apiResponse: ReturnType<typeof Object> = await apiCall.getData()
      // destructuring local refs
      const {
        beaconBarcode,
        specimens,
        patientDob,
        patientName,
        identificationNumber,
        globalPatientNumber,
        robotLocations,
        inventoryUpdate,
        sharedPatientIds = []
      } = apiResponse[0]
      if (!specimens.length) {
        toast.error({ title: `Ticket ${selectedItem.ticketId} has no specimens` })
        await hideSpinner()
        return
      }
      // assignig data to new object
      const newBeacons = {
        beaconBarcode,
        cryoDevice: specimens,
        robotLocations,
        inventoryUpdate
      }
      // splitting full name to name and lastname
      const batchPatientName = patientName.split(' ')
      // setting up patient info
      batchPatient.push({
        birthDate: patientDob,
        firstName: batchPatientName[0],
        lastName: batchPatientName[1],
        globalPatientNumber,
        identificationNumber,
        clinicId: appBaseConfig.value.clinicId,
        siteId: appBaseConfig.value.siteId,
        specimenCount: specimens.length
      })
      batchSharedPatientIds.push(sharedPatientIds)
      // pushing beacons to local ref
      beacons.value.push(newBeacons)
      // pushing apiCache to a localref
      apiCacheResponse.value.push(apiResponse[0])
      // setting up data need it for FinalTicket View
      batchDataToBePesistence(apiCacheResponse.value)
      // in order to avoid backend concunrrency issues and network latency
      // we're waiting till all the api calls have been done
      // we're asserting this by using the index
      const sessionCache = await $ss.getFieldSessionStorage('batch', 'cache')
      if (sessionCache.length === selectedItems.value.length) {
        // @ts-ignore
        const nextStepPath = await executeNextTicketStep({
          selectedMethod: PROCEDURE_TYPE_BATCH_UPPERCASE,
          currentTicketStep: currentTicketStep.value,
          specimenType: specimens[0]?.specimenType,
          patients: batchPatient,
          batchSharedPatientIds,
          // @ts-ignore
          beacons: beacons.value
        })
        await hideSpinner()
        return router.push({ name: nextStepPath })
      }
    })
  } catch (error) {
    await hideSpinner()
    return error
  }
}
const batchDataToBePesistence = (apiCacheResponse: object): void => {
  try {
    // getting selected id's and storing locally
    const childrens: object[] = []
    selectedItems.value.map((selectedItem: { ticketId: object }) =>
      childrens.push(selectedItem.ticketId)
    )
    // getting subType
    const procedureSubType: string = subType.value === 'Store' ? 'IN' : 'OUT'
    // creating object to persist locally
    const dataToPersist = {
      procedureSubType,
      childrens,
      userId: appBaseConfig.value.userId,
      cpId: appBaseConfig.value.cpId,
      subType: subType.value,
      cache: apiCacheResponse
    }
    // assigning the value to a local reference
    setBatchData(dataToPersist)
  } catch (error) {
    throw Error(`batchDataToBePesistence error -> ${error}`)
  }
}
const handleClickBack = () => {
  const previousStepPath = executePreviousTicketStep({
    selectedMethod: selectedMethod.value,
    currentTicketStep: currentTicketStep.value
  })
  router.replace({ name: previousStepPath })
}
const shouldHaveTickets = (elements: Object[]): boolean => {
  if (!elements) return false
  return !!elements.length
}

onMounted(async () => {
  displaySpinner(LOADING_BATCHABLE_TICKETS)
  // construct url
  const url = constructURL()
  // executing the server call
  const apiCall = new ApiCallsFactory(url, appBaseConfig.value.token)
  const apiResponse: ReturnType<typeof Object> = await apiCall.getData()
  // Hide Loader
  processingUpload.value = false
  hideSpinner()
  // assign it the data to a local reference
  data.value = apiResponse
  // composing object response
  const api = new ApiFactory(apiResponse)
  const response = api.compositeResponse()
  // assign it compose it response to local reference
  composedData.value = response
  // creating local view
  apiElementsFactory(true)
  sort.value = new SortFactory({ data: data.value, sortBy: 'procedureTime', direction: 'desc' })
  sortColumn()
  fromRouteName.value = fromRoute?.name
})

watch(selectedItems, (newValue) => {
  if (newValue.length > MAX_BATCH_LENGTH) {
    toast.error({
      message: `You cannot select more than ${MAX_BATCH_LENGTH} ${CRYOBEACONS} in a single Batch Ticket. Please unselect ${CRYOBEACONS} to continue.`
    })
  }
})
</script>
