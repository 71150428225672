<template>
  <section data-test="specimen-detail" class="p-4">
    <top-header back-btn>
      You are viewing {{ CRYODEVICE }} ID
      {{ localSpecimenDetail.cryodeviceBarcode }}
    </top-header>
    <loading-ui v-if="isLoading" modal message="Loading Specimen Details" />
    <template v-else>
      <ActionBar data-test="patient-view__action-bar" cols-distribution="6/6">
        <template v-slot:left-side>
          <dynamic-title
            v-if="
              localSpecimenDetail.location && localSpecimenDetail.location.robotLayoutTypeId !== 0
            "
            title-type="h2"
            float-to="left"
          >
            Location
          </dynamic-title>
        </template>
        <template v-slot:right-side>
          <button-dynamic
            btn-type="button"
            btn-text="Close"
            show-icon
            btn-style="primary"
            add-margin-top
            add-margin-bottom
            font-awesome-icon-class="times-circle"
            @click="backToPatient"
          />
        </template>
      </ActionBar>
      <div
        v-if="localSpecimenDetail.location && localSpecimenDetail.location?.robotLayoutTypeId !== 0"
        data-test="specimen-detail__location-table"
        class="grid gap-4 p-4 sm:grid-cols lg:grid-cols-12 bg-white rounded-md mb-6"
      >
        <div
          v-for="(field, key) in specimenFields"
          :key="key"
          :class="field.addClasses && [...field.addClasses]"
          :data-test="`location-field-${field.dataTest}`"
        >
          <label class="mb-2 text-base text-tmrw-blue">
            {{ field.label }}
          </label>
          <p class="text-xl font-medium text-tmrw-blue-dark border-b border-solid py-2 h-10">
            {{ field.value }}
          </p>
        </div>
      </div>
      <section data-test="specimen-details__details" class="flex flex-col mb-6">
        <dynamic-title title-type="h2" float-to="left" class="mb-2"> Details </dynamic-title>
        <InventorySpecimenTables
          :embryo-specimen-headers="headers.embryoHeaders"
          :egg-specimen-headers="headers.oocyteHeaders"
          :embryo-specimens="embryoSpecimensModel"
          :egg-specimens="eggSpecimensModel"
          class="overflow-auto"
        />
      </section>
      <Patients
        v-if="isMultiplePatientEnabled && hasSharedPatients"
        data-test="specimen-detail__patients"
        class="my-6"
        :ids="patientIds"
        :specimenId="specimenId"
        :patientId="Number(patientId)"
        :cryodeviceBarcode="localSpecimenDetail.cryodeviceBarcode"
      />
      <ActionBar data-test="patient-view__action-bar" cols-distribution="6/6" class="mb-2">
        <template v-slot:left-side>
          <dynamic-title title-type="h2" float-to="left"> Audit Trail </dynamic-title>
        </template>
        <template v-slot:right-side>
          <link-dynamic
            :href="url"
            :download="auditLogCSVFileName"
            link-type="button"
            link-text="Export"
            link-style="secondary"
            show-icon
            addMarginBottom
            font-awesome-icon-class="file-export"
          />
        </template>
      </ActionBar>
      <!-- @vue-ignore -->
      <ScrollTableComponent
        data-test="specimen-detail__audit-trail-table"
        v-model="eventsModel"
        :headers="auditHeaders"
        :overflow-hidden="false"
        class="overflow-auto"
        min-height="h-full"
      />
    </template>
  </section>
</template>

<script setup lang="ts">
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import {
  SPECIMEN_TYPE_EGG,
  SPECIMEN_TYPE_EMBRYO,
  CRYOBEACON,
  CRYODEVICE,
  CRYOGRID,
  LAYOUT_B3C,
  LAYOUT_WAVE,
  LAYOUT_CRS,
  LAYOUT_VAULT
} from '@/constants'
import {
  embryoHeaders,
  oocyteHeaders,
  auditHeaders
} from '@/constants/table-headers/cryodevice-details'
import InventorySpecimenTables from '@/views/InventorySpecimenTables/InventorySpecimenTables.vue'
import LinkDynamic from '@/components/LinkDynamic/LinkDynamic.vue'
import TopHeader from '@/components/TopHeader/TopHeader.vue'
import ActionBar from '@/components/ActionBar/ActionBar.vue'
import ScrollTableComponent from '@/components/ScrollTableComponent/ScrollTableComponent.vue'
import ButtonDynamic from '@/components/ButtonDynamic/ButtonDynamic.vue'
import DynamicTitle from '@/components/DynamicTitle/DynamicTitle.vue'
import toast from '@/config/toast'
import { selectEmbryoTypeLabel } from '@/utils'
import LoadingUi from '@/components/LoadingUi/LoadingUi.vue'
import { replaceStringFilter } from '@/filters'
import Patients from '@/components/SpecimenDetail/Patients/Patients.vue'
import { isFeatureEnabled, MULTIPLE_PATIENTS_ENABLED_FF } from '@/helpers/featureFlags'
import { getShippingSourceName } from '@/helpers/patientHelpers'
import { buildImportedSpecimenToolTipTableData } from '@/helpers/specimenHelpers'
import { getShelfLabel } from '@/helpers/cryoBeaconLocation'
import { computed, onMounted, ref, watch } from 'vue'
import { useTempCryoBeacon } from '@/composables/useTempCryoBeacon'
import useActions from '@/composables/useActions'
import { useRoute, useRouter } from 'vue-router'
import useGetters from '@/composables/useGetters'

dayjs.extend(utc)

type Model = {
  sort: {
    orderBy: string
    direction: string
  }
  options: any[]
}

export type SpecimenFields = {
  label: string
  value: string
  dataTest?: string
  addClasses?: string[]
}

const { showIfValidCryoBeaconId } = useTempCryoBeacon()

const router = useRouter()
const route = useRoute()

const isLoading = ref(true)
const isMultiplePatientEnabled = ref(false)
const url = ref<string | null>(null)
const eventsModel = ref<Model>({
  sort: {
    orderBy: 'dateTime',
    direction: 'desc'
  },
  options: []
})
const localSpecimensModel = ref<Model>({
  sort: {
    orderBy: 'dateTime',
    direction: 'desc'
  },
  options: []
})
const auditLogCSVFileName = ref<string | null>(null)
const baseHeaders = ref({ embryoHeaders, oocyteHeaders })
const headers = ref({ embryoHeaders, oocyteHeaders })

const specimenUnit = ref()

const { specimenDetails, specimenEvents, fileUrl, embryoTypes, cryoDevicesTypes } =
  useGetters('specimensModule')

const { externalClinics } = useGetters('patientsModule')
const { allContainers } = useGetters('siteModule')

const embryoSpecimensModel = computed(() => {
  const specimens = localSpecimensModel.value.options?.filter(
    (specimen) => specimen.specimenType === 'Embryo'
  )
  return {
    sort: {
      orderBy: 'dateTime',
      direction: 'desc'
    },
    options: specimens
  }
})
const eggSpecimensModel = computed(() => {
  const specimens = localSpecimensModel.value.options?.filter(
    (specimen) => specimen.specimenType === 'Egg'
  )
  let options: any = []
  if (specimens) {
    options = specimens
  }

  return {
    sort: {
      orderBy: 'dateTime',
      direction: 'desc'
    },
    options
  }
})
const specimenFields = computed<SpecimenFields[]>(() => {
  const composedSpecimenFields: SpecimenFields[] = []
  if (localSpecimenDetail.value.location) {
    if (localSpecimenDetail.value?.location.robotLayoutTypeId === LAYOUT_B3C) {
      composedSpecimenFields.push(
        {
          label: 'Unit',
          value: specimenUnit.value?.robotName || '',
          dataTest: 'cryo-robot',
          addClasses: ['col-span-2']
        },
        {
          label: 'Rack',
          value: localSpecimenDetail.value.location.rack || '',
          dataTest: 'rack'
        },
        {
          label: 'Shelf',
          value: localSpecimenDetail.value.location.shelf || '',
          dataTest: 'shelf'
        },
        {
          label: 'Position',
          value: localSpecimenDetail.value.location.position || '',
          dataTest: 'position'
        },
        {
          label: `${CRYOGRID} ID`,
          value: localSpecimenDetail.value.location.boxName || '',
          addClasses: ['col-span-3'],
          dataTest: 'cryo-grid'
        },
        {
          label: `${CRYOBEACON} ID`,
          value: showIfValidCryoBeaconId(localSpecimenDetail.value.beaconBarcode),
          addClasses: ['col-span-4'],
          dataTest: 'cryo-beacon'
        }
      )
    }
    if (localSpecimenDetail.value.location?.robotLayoutTypeId === LAYOUT_CRS) {
      composedSpecimenFields.push(
        {
          label: 'Unit',
          value: specimenUnit.value?.robotName || '',
          addClasses: ['col-span-2']
        },
        {
          label: 'Position',
          value: localSpecimenDetail.value.location.position || '',
          addClasses: ['col-span-2']
        },
        {
          label: `${CRYOBEACON} ID`,
          value: showIfValidCryoBeaconId(localSpecimenDetail.value.beaconBarcode),
          addClasses: ['col-span-8']
        }
      )
    }
    if (localSpecimenDetail.value.location?.robotLayoutTypeId === LAYOUT_WAVE) {
      composedSpecimenFields.push(
        {
          label: 'Unit',
          value: specimenUnit.value?.robotName || '',
          addClasses: ['col-span-4']
        },
        {
          label: 'Location',
          value: localSpecimenDetail.value.location.locationDetails || '',
          addClasses: ['col-span-4']
        },
        {
          label: `${CRYOBEACON} ID`,
          value: showIfValidCryoBeaconId(localSpecimenDetail.value.beaconBarcode),
          addClasses: ['col-span-4']
        }
      )
    }
    if (localSpecimenDetail.value?.location.robotLayoutTypeId === LAYOUT_VAULT) {
      composedSpecimenFields.push(
        {
          label: 'Unit',
          value: specimenUnit.value?.robotName || '',
          dataTest: 'cryo-robot',
          addClasses: ['col-span-2']
        },
        {
          label: 'Shelf',
          value: getShelfLabel(localSpecimenDetail.value.location.shelf) || '',
          dataTest: 'shelf'
        },
        {
          label: 'Position',
          value: localSpecimenDetail.value.location.position || '',
          dataTest: 'position'
        },
        {
          label: `${CRYOGRID} ID`,
          value: localSpecimenDetail.value.location.boxName || '',
          addClasses: ['col-span-4'],
          dataTest: 'cryo-grid'
        },
        {
          label: `${CRYOBEACON} ID`,
          value: showIfValidCryoBeaconId(localSpecimenDetail.value.beaconBarcode),
          addClasses: ['col-span-4'],
          dataTest: 'cryo-beacon'
        }
      )
    }
    return composedSpecimenFields
  }
  return []
})
const localSpecimenDetail = computed(() => {
  const baseSpecimenDetails = {
    cryodeviceType: specimenDetails.value.cryodeviceTypeId
      ? cryoDevicesTypes.value.filter(
          (cryo) => Number(cryo.id) === specimenDetails.value.cryodeviceTypeId
        )[0].value
      : '',
    cryoDate: dayjs.utc(Number(specimenDetails.value.cryoDate)).format('DDMMMYYYY').toUpperCase()
  }

  if (specimenDetails.value.specimenType === SPECIMEN_TYPE_EMBRYO) {
    return {
      ...specimenDetails.value,
      ...baseSpecimenDetails,
      embryoTypeLabel: selectEmbryoTypeLabel(specimenDetails.value, embryoTypes.value)
    }
  }
  if (specimenDetails.value.specimenType === SPECIMEN_TYPE_EGG) {
    return {
      ...specimenDetails.value,
      ...baseSpecimenDetails,
      embryoTypeLabel: replaceStringFilter(specimenDetails.value.specimenType)
    }
  }
  return {
    ...baseSpecimenDetails,
    ...specimenDetails.value
  }
})
const localSpecimenInfo = computed(() => {
  return [
    {
      ...localSpecimenDetail.value,
      embryoNumber: localSpecimenDetail.value.embryo
        ? localSpecimenDetail.value.embryo.embryoNumber
        : '',
      maturityLevel: localSpecimenDetail.value.oocyte
        ? localSpecimenDetail.value.oocyte.maturityLevel
        : '',
      biopsy: localSpecimenDetail.value.embryo ? localSpecimenDetail.value.embryo.biopsy : '',
      result:
        localSpecimenDetail.value.embryo && localSpecimenDetail.value.embryo.biopsyResult
          ? localSpecimenDetail.value.embryo.biopsyResult
          : '',
      freeText:
        !!localSpecimenDetail.value.metadata?.metadata?.cryolabelFreeText?.length &&
        localSpecimenDetail.value.metadata.metadata.cryolabelFreeText,
      sources: localSpecimenDetail.value?.sources?.map((source) => ({
        ...source,
        shippingSourceName: getShippingSourceName({
          externalClinics: externalClinics.value,
          shippingSourceId: Number(source.shippingSourceId)
        })
      })),
      sourcesValue: {
        items: buildImportedSpecimenToolTipTableData(localSpecimenDetail.value),
        image: isSpecimenSingleImported.value ? 'importCryoBeacon' : ''
      }
    }
  ]
})
const localSpecimenEvents = computed(() => {
  return specimenEvents.value.map((specimen) => ({
    ...specimen,
    fullName: `${specimen.user.firstName} ${specimen.user.lastName} (${specimen.user.loginName} - Id: ${specimen.user.id})`
  }))
})
const patientId = computed(() => {
  return route.params.patientId
})

const hasSharedPatients = computed(() => {
  if (!specimenDetails.value) return false
  const { sharedPatientIds } = specimenDetails.value
  return Boolean(sharedPatientIds)
})
const patientIds = computed(() => {
  if (!hasSharedPatients.value) return []
  const { sharedPatientIds, cprId } = specimenDetails.value
  return [...sharedPatientIds, cprId]
})
const specimenId = computed(() => {
  return route.params.specimenId as string
})

const isSpecimenSingleImported = computed(() => {
  return !!(specimenDetails.value?.sources && specimenDetails.value?.sources?.length)
})

const {
  fetchSpecimensEvents,
  fetchSpecimenDetails,
  fetchAuditLog,
  fetchEmbryoTypes,
  fetchCryoDevicesTypes
} = useActions('specimensModule')
const { getExternalClinics } = useActions('patientsModule')

const backToPatient = () => {
  router.push({
    path: `/patients/${patientId.value}`
  })
}

onMounted(async () => {
  try {
    isMultiplePatientEnabled.value = isFeatureEnabled(MULTIPLE_PATIENTS_ENABLED_FF)
    await getExternalClinics()
    await fetchEmbryoTypes()
    await fetchCryoDevicesTypes()
    await fetchSpecimenDetails([specimenId.value, patientId.value])
    await fetchSpecimensEvents([specimenId.value, specimenDetails.value.cprId])
    await fetchAuditLog([patientId.value, specimenId.value])
    specimenUnit.value = allContainers.value.find(
      (container) => container.containerId === localSpecimenDetail.value.unitId
    )
    url.value = window.URL.createObjectURL(new Blob([fileUrl.value]))
    auditLogCSVFileName.value = 'audittrail.csv'
  } catch (err) {
    toast.error(err)
  } finally {
    isLoading.value = false
  }
})

watch(specimenEvents, (newValue) => {
  if (newValue) {
    eventsModel.value = {
      ...eventsModel.value,
      options: localSpecimenEvents.value
    }
  }
})

watch(specimenDetails, (newValue) => {
  if (newValue) {
    localSpecimensModel.value = {
      ...localSpecimensModel.value,
      options: localSpecimenInfo.value
    }
  }
})

watch(localSpecimenDetail, () => {
  if (localSpecimenDetail.value.metadata?.metadata?.cryolabelFreeText?.length) {
    // Free Text column object to include in Embryo or Oocyte if value is set
    const freeTextColumn = {
      name: 'Free Text',
      sort: false,
      key: 'metadata.metadata.cryolabelFreeText',
      cssClasses: ['truncate', 'w-24'],
      component: 'tooltip',
      componentOptions: {
        alignment: 'left',
        maxlength: 10
      }
    }
    // Set index to insert the Free Text Field column.
    const specimenHeadersInsertIndex =
      localSpecimenDetail.value.specimenType === SPECIMEN_TYPE_EMBRYO ? 11 : 8
    // Determine and isolate the specimen specific object to insert the header to.
    const specimenTypeHeadersObject =
      localSpecimenDetail.value.specimenType === SPECIMEN_TYPE_EMBRYO
        ? 'embryoHeaders'
        : 'oocyteHeaders'
    headers.value = {
      ...headers.value,
      [specimenTypeHeadersObject]: baseHeaders.value[specimenTypeHeadersObject]
    }
    headers.value[specimenTypeHeadersObject].splice(specimenHeadersInsertIndex, 0, freeTextColumn)
  }
})
</script>
