import type { GlobalStore, GlobalActions } from './types'

export const actions: GlobalActions = {
  showAppReload(this: GlobalStore, show: boolean) {
    this.$patch({ appReload: show })
  },
  showAppUpdated(this: GlobalStore, show: boolean) {
    this.$patch({ appUpdated: show })
  }
}
