<template>
  <div :data-test="dataTest" class="custom-select" :class="[{ 'bg-tmrw-error-light': showError }]">
    <p v-if="customText" data-test="select-custom-text" class="text-xs font-bold text-tmrw-blue">
      {{ customText }}
    </p>
    <span v-if="isLabelVisible" class="text-sm text-tmrw-blue">{{ defaultOptionLabel }}</span>
    <select
      class="custom-select__dropdown w-full font-medium leading-tight outline-none rounded-none border-b border-solid border-tmrw-blue-dark pr-5"
      :class="[
        textColor,
        hasLargePadding ? 'py-2' : 'p-1.5',
        hasFontInconsolata ? 'font-inconsolata' : 'font-exo',
        hasLargeFont ? 'text-xl' : 'text-base'
      ]"
      @input="handleInput"
      :value="modelValue"
      :disabled="disabledSelect"
    >
      <option
        v-if="allowEmpty"
        :disabled="isDefaultOptionDisabled"
        :hidden="isDefaultOptionHidden"
        value=""
      >
        {{ defaultOptionLabel }}
      </option>
      <option
        v-for="option in filteredOptions"
        :key="option.value"
        :value="option.value"
        :disabled="option.disabled"
        v-html="$sanitize(option.label)"
      />
    </select>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'custom-select',
  emits: ['update:modelValue'],
  props: {
    modelValue: {
      required: false
    },
    isDefaultOptionDisabled: {
      type: Boolean,
      required: false,
      default: false
    },
    customText: {
      type: String || Boolean,
      required: false,
      default: false
    },
    isLabelVisible: {
      type: Boolean,
      required: false,
      default: false
    },
    isDefaultOptionHidden: {
      type: Boolean,
      required: false,
      default: false
    },
    options: {
      type: [Array, Object],
      required: true
    },
    allowEmpty: {
      type: Boolean,
      default: true
    },
    dataTest: {
      type: String,
      default: 'custom-select'
    },
    textColor: {
      type: String,
      default: 'text-tmrw-blue-dark'
    },
    defaultOptionLabel: {
      type: String,
      default: 'Please select one'
    },
    addPaddingTop: {
      type: Boolean,
      default: false,
      required: false
    },
    addPaddingBottom: {
      type: Boolean,
      default: false,
      required: false
    },
    addMarginTop: {
      type: Boolean,
      default: false,
      required: false
    },
    addMarginBottom: {
      type: Boolean,
      default: false,
      required: false
    },
    hasLargePadding: {
      type: Boolean,
      default: false,
      required: false
    },
    hasFontInconsolata: {
      type: Boolean,
      default: false,
      required: false
    },
    disabledSelect: {
      type: Boolean,
      default: false,
      required: false
    },
    noTemporaryContainers: {
      type: Boolean,
      default: false,
      required: false
    },
    noNonTmrwLocation: {
      type: Boolean,
      default: false,
      required: false
    },
    showError: {
      type: Boolean,
      default: false,
      required: false
    },
    hasLargeFont: {
      type: Boolean,
      default: false,
      required: false
    },
    placeholderColor: {
      type: String,
      default: 'tmrw-blue'
    }
  },
  computed: {
    ...mapGetters('authModule', ['loggedUserInfo', 'appBaseConfig']),
    filteredOptions() {
      if (this.noTemporaryContainers && this.noNonTmrwLocation) {
        return this.options.filter(
          (option) => option.robotLayoutTypeId !== 0 && option.robotLayoutTypeId !== 3
        )
      }
      if (this.noTemporaryContainers) {
        return this.options.filter((option) => option.robotLayoutTypeId !== 0)
      }
      if (this.noNonTmrwLocation) {
        return this.options.filter((option) => option.robotLayoutTypeId !== 3)
      }
      return this.options
    }
  },
  methods: {
    handleInput(event) {
      const { redirectedFrom, name: viewName, currentRoute } = this.$router.currentRoute.value
      const { hostname } = window.location
      this.$mixpanel.track(`Click on Custom Select ${this.dataTest}`, {
        component: 'Custom Select',
        'default-option-label': this.defaultOptionLabel,
        'data-test': this.dataTest,
        'selected-value': event.target.value,
        'current-route': currentRoute,
        'view-name': viewName,
        'redirected-from': redirectedFrom?.path,
        'clinic-name': this.appBaseConfig.clinicName,
        'site-name': this.appBaseConfig.siteName,
        'user-name': this.loggedUserInfo.loginName,
        'user-type': this.loggedUserInfo.userType,
        hostname
      })
      this.$emit('update:modelValue', event.target.value)
    }
  }
}
</script>

<style scoped lang="scss">
.custom-select {
  &__dropdown {
    appearance: none;
    @apply bg-white;
    background: url('../../assets/arrow-down.svg') center right 8px no-repeat;
    background-size: 10px auto;
    -moz-appearance: none;
    -webkit-appearance: none;
  }

  select::-ms-expand {
    display: none;
  }

  select:focus {
    @apply bg-tmrw-gray-light;
  }
}
</style>
