<template>
  <div
    v-if="!edit"
    data-testid="disabled-options-field"
    :data-test="`options-field-${itemKey}`"
    class="bg-white text-tmrw-blue py-1.5 leading-5 border-b border-dotted border-tmrw-blue w-full block"
  >
    {{ getSelectedValue }}<slot />
  </div>
  <div v-else data-test="options-field" class="w-full border-b border-solid py-2">
    <select
      data-test="options-field-select"
      :data-testid="`options-field-${itemKey}`"
      class="options-field__select appearance-none outline-none w-full"
      placeholder=""
      @change="handleChange"
      @keydown="onKeyPress"
      required
      :tabindex="tabKey"
      v-model="selectedValue"
    >
      <option v-if="!defaultValue" selected value="">--</option>
      <option :key="option.value" v-for="option in options" :value="option.value">
        {{ option.label }}
      </option>
    </select>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'options-field',
  data() {
    return {
      selectedValue: null
    }
  },
  emits: ['changeValue'],
  props: {
    value: {
      type: [String, Number],
      required: false,
      default: ''
    },
    tabKey: {
      type: Number,
      required: false,
      default: 0
    },
    edit: {
      type: Boolean,
      required: false,
      default: true
    },
    size: {
      type: String,
      required: false,
      default: 'medium'
    },
    options: {
      type: Array,
      required: false,
      default: () => []
    },
    defaultValue: {
      type: [String, Number],
      required: false,
      default: undefined
    },
    itemKey: {
      type: String
    }
  },
  methods: {
    handleChange(event) {
      const { redirectedFrom, name: viewName, currentRoute } = this.$router.currentRoute.value
      const { hostname } = window.location
      this.$mixpanel.track(`Click on Options Field ${this.dataTest}`, {
        component: 'Options Field',
        'default-value': this.defaultValue,
        'selected-value': event.currentTarget.value,
        'current-route': currentRoute,
        'view-name': viewName,
        'redirected-from': redirectedFrom?.path,
        'clinic-name': this.appBaseConfig.clinicName,
        'site-name': this.appBaseConfig.siteName,
        'user-name': this.loggedUserInfo.loginName,
        'user-type': this.loggedUserInfo.userType,
        hostname
      })
      this.$emit('changeValue', event.currentTarget.value)
    },
    onKeyPress(event) {
      if (isNaN(event.key)) {
        return
      }
      let index = event.key * 1 - 1
      const option = this.options[index]
      if (option) {
        this.selectedValue = option.value
        this.handleChange({
          currentTarget: {
            value: option.value
          }
        })
      }
    }
  },
  computed: {
    ...mapGetters('authModule', ['loggedUserInfo', 'appBaseConfig']),
    getSelectedValue() {
      const [selectedOption] = this.options.filter((option) => option.value === this.value)
      if (selectedOption && selectedOption.label) {
        return selectedOption.label
      }
      return this.value || '--'
    }
  },
  mounted() {
    this.selectedValue = this.value ? this.value : this.defaultValue
    this.$emit('changeValue', this.selectedValue)
  }
}
</script>

<style lang="scss" scoped>
.options-field {
  &__select {
    background: #fff url('../../assets/arrow-down.svg') center right 8px no-repeat;
    background-size: 10px auto;
  }
}

select:focus {
  @apply bg-tmrw-gray-light;
}
</style>
